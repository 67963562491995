// 课程路由
export const pay = [
	{
		path: '/pay',
		name: '确认订单',
		component: (resolve) => require(['@p/pay/pay.vue'], resolve),
		meta: {
			title: '确认订单',
		},
	},
    {
		path: '/paymentReturn',
		name: '支付结果',
		component: (resolve) => require(['@p/pay/components/paymentReturn.vue'], resolve),
		meta: {
			title: '支付结果',
		},
	},
];
