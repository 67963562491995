import { render, staticRenderFns } from "./newlearn.vue?vue&type=template&id=6365189a&scoped=true&"
import script from "./newlearn.vue?vue&type=script&lang=js&"
export * from "./newlearn.vue?vue&type=script&lang=js&"
import style0 from "./newlearn.vue?vue&type=style&index=0&id=6365189a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6365189a",
  null
  
)

export default component.exports