<template>
  <div class="liveWrap">
    <Header :bgColor="bgColor"></Header>
    <main :class="bgColor ? 'mainBgWhite' : 'mainBgBlack'">
      <div class="videoWrap" :class="openFlag ? 'pr400' : ''" @mouseenter="showT = !showT" @mouseleave="showT = !showT" @click="clpl">
        <img src="./assets/images/icon_bofang.png" alt="" class="play" @click.stop="plays" v-if="picshow">
        <div class="videoBox">
          <i
            v-if="bgColor"
            class="opencloseBox"
            :class="openFlag ? 'openwhite posiR14' : 'closewhite posiR0'"
            @click="changeOpen"
          ></i>
          <i
            v-else
            class="opencloseBox"
            :class="openFlag ? 'openblack posiR14' : 'closeblack posiR0'"
            @click="changeOpen"
          ></i>
          <div class="topWrap" v-if="showT" @mouseenter="showT = true">
            <img src="./assets/images/back.png" alt="" @click.stop="goNewLearn"/>{{ kejian_title }}
          </div>
          <div class="bottomWrap">
            <ul class="bottomTitle">
              <li @click="changeBg">
                <i
                  :class="bgColor ? 'iconfont icon-yjms' : 'iconfont icon-btms'"
                ></i
                ><span v-if="bgColor"> 夜间模式</span
                ><span v-else>白天模式</span>
              </li>
            </ul>
          </div>
          <video :src="srcs" controls="controls" id="offcnplayer"  @canplay="getVidDur()" @ended="getend" />
        </div>
      </div>
      <div
        class="listWrap"
        :class="bgColor ? 'listBgWhite' : 'listBgBlack'"
        v-if="openFlag"
        ref="listScrollBox"
      >
        <div class="modelWrap" v-if="modelFlag">
          <img src="./assets/images/cgms.png" alt="" />
          闯关模式
          <i class="iconfont icon-home_yjfk" @click="showdiag"></i>
        </div>
        <p class="listTitle">目录</p>

        <hr />
        <!-- 课程 -->
        <ul class="listItemWrap" v-if="lessonFlag">
          <li
            class="contentItem"
            v-for="(it, i) in lessonData"
            :key="i"
            :class="modelFlag && it.over == 0 ? 'contentItemMask' : ''"
            @click="jump(it)" :id="'less'+it.id"
          >
            <span class="contentIndex">{{
              it.number < 10 ? "0" + it.number : it.number
            }}</span>
            <img
              v-if="modelFlag && it.over == 0"
              :src="require('./assets/images/lock.png')"
              alt=""
              class="contentCate"
            />
            <img
              v-else
              :src="require('./assets/images/s' + it.type + '.png')"
              alt=""
              class="contentCate"
            />
            <div class="contentDetail">
              <p :class="{'colorBlue':it.id==lesson_id}">{{ it.title }}</p>
              <div class="contentInfo">
                <div>
                  <span style="margin-right: 10px" class="tag">{{
                    it.media_name
                  }}</span>
                  <span
                    style="margin-right: 10px"
                    class="tag"
                    v-if="it.type == 4&&it.resources_type_name"
                  >
                    {{ it.resources_type_name }}</span
                  >
                  <template v-if="it.type != 6">
                    <span v-if="it.live_sta_time != 0"
                      >{{ it.live_sta_time | formatimes("yyyy-MM-dd hh:mm") }}
                      -
                      {{ it.live_end_time | formatimes("hh:mm") }}</span
                    >
                  </template>
                  <template v-else>
                    <span v-if="it.live_sta_time != 0 && it.live_end_time != 0"
                      >{{ it.live_sta_time | formatimes("yyyy-MM-dd hh:mm") }}
                      -
                      {{ it.live_end_time | formatimes("MM-dd hh:mm") }}</span
                    >
                    <span v-if="it.live_sta_time != 0 && it.live_end_time == 0"
                      >{{ it.live_sta_time | formatimes("yyyy-MM-dd hh:mm") }}
                    </span>
                    <span v-if="it.live_sta_time == 0 && it.live_end_time != 0"
                      >截至{{
                        it.live_end_time | formatimes("MM-dd hh:mm")
                      }}</span
                    >
                  </template>
                  <template v-if="it.type == 8">
                    <span v-if="it.media_length"
                      >{{ it.media_length | byteConvert }}
                    </span>
                  </template>
                  <template v-else>
                    <span v-if="it.media_length && it.type != 1"
                      >{{ it.media_length }}{{ it.type == 3 ? "道题" : "" }}
                    </span>
                    <span v-if="it.media_length && it.type == 1"
                      >{{ it.media_length }}
                    </span>
                  </template>
                  <span
                    v-if="it.paper_time_limit && it.type == 3"
                    class="colorRed"
                    ><i class="iconfont icon-hsfz"></i>
                    {{ it.paper_time_limit }}
                  </span>
                  <span v-if="it.finished == 1" class="colorGreen"
                    ><i class="iconfont icon-yxw"></i> 已学完</span
                  >
                  <span
                    v-if="
                      it.finished == 0 &&
                      it.score &&
                      it.type != 3 &&
                      it.type != 5 &&
                      it.type != 9
                    "
                  >
                    <i class="iconfont icon-history"></i>已学{{ it.score }}
                  </span>
                </div>
                <div class="mt10 statusDiv">
                  <template
                    v-if="
                      it.type == 2 &&
                      it.live_sta_time - servertime < 0 &&
                      it.live_end_time - servertime > 0
                    "
                  >
                    <div class="colorBlue">
                      <img src="@/assets/images/living_blue.gif" alt="" />
                      直播中
                    </div>
                  </template>
                  <div class="colorBlue" v-if="it.type == 4">查看</div>
                  <div
                    class="colorBlue"
                    v-if="(it.type == 3 || it.type == 5) && it.answer_id == 0"
                  >
                    提交
                  </div>
                  <div
                    class="colorBlue"
                    v-if="
                      (it.type == 3 || it.type == 5) &&
                      it.answer_id != 0 &&
                      it.answer_status == 0
                    "
                  >
                    继续作答
                  </div>
                  <div
                    class="colorBlue"
                    v-if="
                      (it.type == 3 || it.type == 5) &&
                      it.answer_id != 0 &&
                      it.score
                    "
                  >
                    {{ it.score }}
                  </div>
                  <div class="colorBlue" v-if="it.type == 9 && it.score">
                    已读完
                  </div>
                  <div class="colorBlue" v-if="(it.type == 1||it.type == 7)&&it.id==lesson_id">
                    <i class="iconfont icon-play"></i> 播放中
                  </div>
                  <div
                    class="colorBlue"
                    v-if="
                      it.type == 2 &&
                      it.live_sta_time - servertime < 3600 &&
                      it.live_sta_time - servertime > 0
                    "
                  >
                    即将开始
                  </div>
                  <div
                    class="colorGray"
                    v-if="it.type == 2 && it.live_sta_time - servertime > 3600"
                  >
                    未开始
                  </div>
                  <div
                    class="colorGray"
                    v-if="it.type == 2 && servertime - it.live_end_time > 0"
                  >
                    已结束
                  </div>
                  <div class="colorBlue" v-if="it.type == 8">立即阅读</div>
                  <template v-if="modelFlag && it.over == 0">
                    <div
                      :class="
                        servertime > it.live_end_time ||
                        it.live_sta_time > servertime
                          ? 'colorGray'
                          : 'colorBlue'
                      "
                      v-if="it.type == 6"
                    >
                      {{
                        servertime > it.live_end_time
                          ? "已结束"
                          : it.live_sta_time > servertime
                          ? "未开始"
                          : it.finished
                          ? "查看"
                          : "提交"
                      }}
                    </div>
                  </template>
                  <template v-else>
                    <template
                      v-if="
                        it.type == 6 && it.live_end_time && it.live_sta_time
                      "
                    >
                      <div
                        class="colorBlue"
                        v-if="it.live_end_time > servertime && servertime > it.live_sta_time"
                      >
                        {{ it.finished ? "查看" : "提交" }}
                      </div>
                      <div
                        class="colorGray"
                        v-if="
                          servertime > it.live_end_time ||
                          it.live_sta_time > servertime
                        "
                      >
                        {{
                          servertime > it.live_end_time
                            ? "已结束"
                            : it.live_sta_time > servertime
                            ? "未开始"
                            : ""
                        }}
                      </div>
                    </template>
                    <template
                      v-if="
                        it.type == 6 && !it.live_end_time && it.live_sta_time
                      "
                    >
                      <div
                        class="colorBlue"
                        v-if="servertime > it.live_sta_time"
                      >
                        {{ it.finished ? "查看" : "提交" }}
                      </div>
                      <div
                        class="colorGray"
                        v-if="it.live_sta_time > servertime"
                      >
                        未开始
                      </div>
                    </template>
                    <template
                      v-if="
                        it.type == 6 && it.live_end_time && !it.live_sta_time
                      "
                    >
                      <div
                        class="colorBlue"
                        v-if="it.live_end_time > servertime"
                      >
                        {{ it.finished ? "查看" : "提交" }}
                      </div>
                      <div
                        class="colorGray"
                        v-if="it.live_end_time < servertime"
                      >
                        已结束
                      </div>
                    </template>
                    <template
                            v-if="
                              it.type == 6 &&
                              it.live_end_time==0 &&
                              it.live_sta_time==0
                            "
                    >
                      <div
                              class="btnWrap"
                              @click="questionnaire(it)"
                      >
                        <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                        ></i
                        >{{ it.finished ? "查看" : "提交" }}
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </li>
          <li> <p v-if="hideMsg" class="hideMsgWrap">没有更多了</p></li>
        </ul>
        <!-- 章节课程 -->
        <ul class="listItemWrap" v-else>
          <li class="listItem" v-for="(item, index) in lessonData" :key="index">
            <div class="itemTitle" @click="showListItem(item)">
              <div class="titleLeft">
                <i class="circle"></i>
                <span>第{{item.number}}章 {{ item.title }}</span>
              </div>
              <i class="arrow el-icon-arrow-up" v-if="item.showFlag"></i>
              <i class="arrow el-icon-arrow-down" v-else></i>
            </div>
            <template v-if="item.showFlag">
              <div
                class="itemContent"
                v-for="(ite, ind) in item.list"
                :key="ind"
              >
                <div
                  class="contentTitle"
                  v-if="ite.title"
                  @click="showListItemChild(ite)"
                >
                  <p>第{{ite.number}}节 {{ ite.title }}</p>
                  <i
                    class="arrow el-icon-arrow-up"
                    v-if="ite.showFlagChild"
                  ></i>
                  <i class="arrow el-icon-arrow-down" v-else></i>
                </div>
                <template v-if="ite.showFlagChild || !ite.title">
                  <ul>
                    <li
                      v-for="(it, i) in ite.list"
                      :key="i"
                      class="contentItem"
                      :class="
                        modelFlag && it.over == 0 ? 'contentItemMask' : ''
                      "
                      @click="jump(it)" :id="'less'+it.id"
                    >
                      <span class="contentIndex">{{
                        it.number < 10 ? "0" + it.number : it.number
                      }}</span>
                      <img
                        v-if="modelFlag && it.over == 0"
                        :src="require('./assets/images/lock.png')"
                        alt=""
                        class="contentCate"
                      />
                      <img
                        v-else
                        :src="require('./assets/images/s' + it.type + '.png')"
                        alt=""
                        class="contentCate"
                      />
                      <div class="contentDetail">
                        <p :class="{'colorBlue':it.id==lesson_id}">{{ it.title }}</p>
                        <div class="contentInfo">
                          <div>
                            <span style="margin-right: 10px" class="tag">{{
                              it.media_name
                            }}</span>
                            <span
                              style="margin-right: 10px"
                              class="tag"
                              v-if="it.type == 4&&it.resources_type_name"
                            >
                              {{ it.resources_type_name }}</span
                            >
                            <template v-if="it.type != 6">
                              <span v-if="it.live_sta_time != 0"
                                >{{
                                  it.live_sta_time
                                    | formatimes("yyyy-MM-dd hh:mm")
                                }}
                                -
                                {{
                                  it.live_end_time | formatimes("hh:mm")
                                }}</span
                              >
                            </template>
                            <template v-else>
                              <span
                                v-if="
                                  it.live_sta_time != 0 && it.live_end_time != 0
                                "
                                >{{
                                  it.live_sta_time
                                    | formatimes("yyyy-MM-dd hh:mm")
                                }}
                                -
                                {{
                                  it.live_end_time | formatimes("MM-dd hh:mm")
                                }}</span
                              >
                              <span
                                v-if="
                                  it.live_sta_time != 0 && it.live_end_time == 0
                                "
                                >{{
                                  it.live_sta_time
                                    | formatimes("yyyy-MM-dd hh:mm")
                                }}
                              </span>
                              <span
                                v-if="
                                  it.live_sta_time == 0 && it.live_end_time != 0
                                "
                                >截至{{
                                  it.live_end_time | formatimes("MM-dd hh:mm")
                                }}</span
                              >
                            </template>
                            <template v-if="it.type == 8">
                              <span v-if="it.media_length"
                                >{{ it.media_length | byteConvert }}
                              </span>
                            </template>
                            <template v-else>
                              <span v-if="it.media_length && it.type != 1"
                                >{{ it.media_length
                                }}{{ it.type == 3 ? "道题" : "" }}
                              </span>
                              <span v-if="it.media_length && it.type == 1"
                                >{{ it.media_length }}
                              </span>
                            </template>
                            <span
                              v-if="it.paper_time_limit && it.type == 3"
                              class="colorRed"
                              ><i class="iconfont icon-hsfz"></i>
                              {{ it.paper_time_limit }}
                            </span>
                            <span v-if="it.finished == 1" class="colorGreen"
                              ><i class="iconfont icon-yxw"></i> 已学完</span
                            >
                            <span
                              v-if="
                                it.finished == 0 &&
                                it.score &&
                                it.type != 3 &&
                                it.type != 5 &&
                                it.type != 9
                              "
                            >
                              <i class="iconfont icon-history"></i>已学{{
                                it.score
                              }}
                            </span>
                          </div>
                          <div class="mt10 statusDiv">
                            <template
                              v-if="
                                it.type == 2 &&
                                it.live_sta_time - servertime < 0 &&
                                it.live_end_time - servertime > 0
                              "
                            >
                              <div class="colorBlue">
                                <img
                                  src="@/assets/images/living_blue.gif"
                                  alt=""
                                />
                                直播中
                              </div>
                            </template>
                            <div class="colorBlue" v-if="it.type == 4">
                              查看
                            </div>
                            <div
                              class="colorBlue"
                              v-if="
                                (it.type == 3 || it.type == 5) &&
                                it.answer_id == 0
                              "
                            >
                              提交
                            </div>
                            <div
                              class="colorBlue"
                              v-if="
                                (it.type == 3 || it.type == 5) &&
                                it.answer_id != 0 &&
                                it.answer_status == 0
                              "
                            >
                              继续作答
                            </div>
                            <div
                              class="colorBlue"
                              v-if="
                                (it.type == 3 || it.type == 5) &&
                                it.answer_id != 0 &&
                                it.score
                              "
                            >
                              {{ it.score }}
                            </div>
                            <div
                              class="colorBlue"
                              v-if="it.type == 9 && it.score"
                            >
                              已读完
                            </div>
                            <div class="colorBlue" v-if="(it.type == 1||it.type == 7)&&it.id==lesson_id">
                              <i class="iconfont icon-play"></i> 播放中
                            </div>
                            <div
                              class="colorBlue"
                              v-if="
                                it.type == 2 &&
                                it.live_sta_time - servertime < 3600 &&
                                it.live_sta_time - servertime > 0
                              "
                            >
                              即将开始
                            </div>
                            <div
                              class="colorGray"
                              v-if="
                                it.type == 2 &&
                                it.live_sta_time - servertime > 3600
                              "
                            >
                              未开始
                            </div>
                            <div
                              class="colorGray"
                              v-if="
                                it.type == 2 &&
                                servertime - it.live_end_time > 0
                              "
                            >
                              已结束
                            </div>
                            <div class="colorBlue" v-if="it.type == 8">
                              立即阅读
                            </div>
                            <template v-if="modelFlag && it.over == 0">
                              <div
                                :class="
                                  servertime > it.live_end_time ||
                                  it.live_sta_time > servertime
                                    ? 'colorGray'
                                    : 'colorBlue'
                                "
                                v-if="it.type == 6"
                              >
                                {{
                                  servertime > it.live_end_time
                                    ? "已结束"
                                    : it.live_sta_time > servertime
                                    ? "未开始"
                                    : it.finished
                                    ? "查看"
                                    : "提交"
                                }}
                              </div>
                            </template>
                            <template v-else>
                              <template
                                v-if="
                                  it.type == 6 &&
                                  it.live_end_time &&
                                  it.live_sta_time
                                "
                              >
                                <div
                                  class="colorBlue"
                                  v-if="
                                    it.live_end_time > servertime && servertime > it.live_sta_time
                                  "
                                >
                                  {{ it.finished ? "查看" : "提交" }}
                                </div>
                                <div
                                  class="colorGray"
                                  v-if="
                                    servertime > it.live_end_time ||
                                    it.live_sta_time > servertime
                                  "
                                >
                                  {{
                                    servertime > it.live_end_time
                                      ? "已结束"
                                      : it.live_sta_time > servertime
                                      ? "未开始"
                                      : ""
                                  }}
                                </div>
                              </template>
                              <template
                                v-if="
                                  it.type == 6 &&
                                  !it.live_end_time &&
                                  it.live_sta_time
                                "
                              >
                                <div
                                  class="colorBlue"
                                  v-if="servertime > it.live_sta_time"
                                >
                                  {{ it.finished ? "查看" : "提交" }}
                                </div>
                                <div
                                  class="colorGray"
                                  v-if="it.live_sta_time > servertime"
                                >
                                  未开始
                                </div>
                              </template>
                              <template
                                v-if="
                                  it.type == 6 &&
                                  it.live_end_time &&
                                  !it.live_sta_time
                                "
                              >
                                <div
                                  class="colorBlue"
                                  v-if="it.live_end_time > servertime"
                                >
                                  {{ it.finished ? "查看" : "提交" }}
                                </div>
                                <div
                                  class="colorGray"
                                  v-if="it.live_end_time < servertime"
                                >
                                  已结束
                                </div>
                              </template>
                              <template
                                      v-if="
                              it.type == 6 &&
                              it.live_end_time==0 &&
                              it.live_sta_time==0
                            "
                              >
                                <div
                                        class="btnWrap"
                                        @click="questionnaire(it)"
                                >
                                  <i
                                          class="iconfont icon-maskLock"
                                          v-if="modelFlag && it.over == 0"
                                  ></i
                                  >{{ it.finished ? "查看" : "提交" }}
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
              </div>
            </template>
          </li>
          <li> </li>
        </ul>

      </div>
    </main>
    <CourseAssess ref="CourseAssess"></CourseAssess>
    <FeedbakcDia ref="FeedbakcDia"></FeedbakcDia>
    <ModelDia ref="ModelDia"></ModelDia>
  </div>
</template>
<script>
import Header from "./components/header.vue";
import CourseAssess from "./components/courseAssess.vue";
import FeedbakcDia from "./components/feedbackDia.vue";
import ModelDia from "@/views/components/modelDia/modelDia";
import { videoinfo, report, downFile ,lessonList,ebookinfo} from "./assets/api.js";
export default {
  components: { Header, CourseAssess, FeedbakcDia, ModelDia },
  data() {
    return {
      showT:false,
      bgColor: true,
      openFlag: true, //true
      listData: [],
      lessonData: [],
      lessonFlag: true,
      srcs: {},
      watchtimes: 0,
      watchtimer: 0,
      initialimer: 0,
      timer: "",
      kejian_title: "",
      page: 1,
      limit: 10,
      servertime: "",
      data_lesson_list: [],
      data_chapters_list: [],
      modelFlag: "",
      course_id: "",
      lesson_id: "",
      hideMsg: false,
      cateListNum: 0,
      morelist:[],
      page0:'',
      picshow:true,
    };
  },
  mounted() {
    this.course_id = this.$route.query.course_id;
    this.lesson_id = this.$route.query.lesson_id;

    this.modelFlag = this.$route.query.modelFlag == 'undefined' ? false : true;
    this.getinfos(1);
    this.timer = setInterval(this.getreport, 15000);
    // let box = this.$refs.listScrollBox;
    // box.addEventListener("scroll", this.handleScroll, true);

  },
  methods: {
    goNewLearn(){
        this.$router.push({
            path:'/newlearn',
            query:{
                course_id:this.$route.query.course_id,
                cst_id:this.$route.query.cst_id
            }
        })
    },
    clpl(){
      this.picshow = !this.picshow
    },
    plays(){
      // this.picshow = !this.picshow
      if(this.picshow){
        this.picshow = false
        document.getElementById("offcnplayer").play()
      }
    },
    getinfos(val) {
      this.cateListNum++;
      let params = {
        course_id: this.course_id,
        lesson_id: this.lesson_id,
        // lessonType:'',
        // page: this.page,
        // limit: this.limit,
      };
      videoinfo(params).then((res) => {
        if (res.code == 0) {
          this.kejian_title = res.data.vresult.title;

          if(val==1){
          // this.page = res.data.page;
          // this.page0 = res.data.page;
          this.servertime = res.data.servertime;

          if (res.data.lesson_list.length) {
            this.morelist = res.data.lesson_list
            this.data_lesson_list = res.data.lesson_list

          }
          if (this.cateListNum == 1) {
            if (res.data.chapters_list.length) {
              this.data_chapters_list =  res.data.chapters_list
            }
          }
            this.data_lesson_list.map((item) => {
              if(item.type==6&&item.live_end_time<this.servertime){
                this.$set(item, "over", 1);
              }else{
                this.$set(item, "over", item.finished);
              }
              // this.$set(item, "over", item.finished);
            });
            var x;
            if (this.data_lesson_list.length > 0) {
              x = this.data_lesson_list.findIndex(
                      (item) => item.over == 0
              );
              if(x!=-1){
                this.data_lesson_list[x].over = 1;
              }else{
                this.data_lesson_list[0].over = 0;
              }
            }
            this.data_lesson_list.map((item,index) => {
              if(index>x){
                this.$set(item, "over", 0);
              }

            });
          if (this.data_chapters_list.length > 0) {
            this.lessonFlag = false;
            this.data_chapters_list.map((item) => {
              if (item.list.length == 0) {
                item.list.push({ id: item.id, list: [], title: "" });
                item.list.map((it) => {
                  this.morelist.map((ite) => {
                    if (ite.chapter_id == it.id) {
                      it.list.push(ite);
                    }
                  });
                });
              } else {
                item.list.map((it) => {
                  this.morelist.map((ite) => {
                    if (ite.chapter_id == it.id) {
                      it.list.push(ite);
                    }
                  });
                });
              }
            });
            this.lessonData = this.data_chapters_list;
            this.lessonData.map((item) => {
              this.$set(item, "showFlag", true);
              item.list.forEach((ite) => {
                if (ite.title) {
                  this.$set(ite, "showFlagChild", true);
                  ite.list.map((it) => {
                    this.$set(it, "web_lesson_type", "lesson");
                  });
                }
              });
            });
          } else {
            this.lessonFlag = true;
            this.lessonData = this.data_lesson_list;
          }

        }
          // currentTime
          var video = document.getElementById("offcnplayer");
          var hls = new Hls();
          hls.loadSource(
                  "https://kejian3.offcn.com/" + res.data.vresult.m3u8url,
                  encodeURIComponent(res.data.vresult.m3u8)
          );
          hls.attachMedia(video);
          this.watchtimes = res.data.vresult.watchtimes;
          video.currentTime = this.watchtimes;
          if (this.watchtimes == 0) {
            this.initialimer = 0;
          } else {
            function times(data) {
              let s =
                      parseInt(data % 60) < 10
                              ? "0" + parseInt(data % 60)
                              : parseInt(data % 60);
              let m =
                      parseInt((data / 60) % 60) < 10
                              ? "0" + parseInt((data / 60) % 60)
                              : parseInt((data / 60) % 60);
              let h = parseInt(data / 60 / 60);
              return h + ":" + m + ":" + s;
            }
            this.$message.success(
                    "上次观看至" + times(this.watchtimes) + ",正在续播"
            );
            this.initialimer = this.watchtimes;
          }
        }
        this.$nextTick(()=>{
          this.handleScroll(this.lesson_id)
        })
      });
    },
    getinfosmore(val) {
      this.cateListNum++;
      let params = {
        course_id: this.course_id,
        lesson_id: this.lesson_id,
        // lessonType:'',
        page: val==0?this.page0:this.page,
        limit: this.limit,
      };
      lessonList(params).then((res) => {
        if (res.code == 0) {
          this.servertime = res.data.servertime;
          if (res.data.lesson_list.length) {
            // this.morelist = res.data.lesson_list.reverse()

            if(val==0){
              this.morelist = res.data.lesson_list.reverse()
              this.data_lesson_list = res.data.lesson_list.concat(
                      this.data_lesson_list
              );
            }else{
              this.morelist = res.data.lesson_list
              this.data_lesson_list = this.data_lesson_list.concat(
                      res.data.lesson_list
              );
            }
          } else {
            this.morelist=[]
            this.hideMsg = true;
          }
          if (this.cateListNum == 1) {
            if (res.data.chapters_list.length) {
              this.data_chapters_list = this.data_chapters_list.concat(
                      res.data.chapters_list
              );
            }
          }
          this.data_lesson_list.map((item) => {
            this.$set(item, "over", item.finished);
          });
          if (this.data_lesson_list.length > 0) {
            let x = this.data_lesson_list.findIndex(
                    (item) => item.finished == 0
            );
            if (x != -1) {
              this.data_lesson_list[x].over = 1;
            }
          }
          if (this.data_chapters_list.length > 0) {
            this.lessonFlag = false;
            this.data_chapters_list.map((item) => {
              if (item.list.length == 0) {
                item.list.push({ id: item.id, list: [], title: "" });
                item.list.map((it) => {
                  this.morelist.map((ite) => {
                    if (ite.chapter_id == it.id) {
                      if(val==0){
                        it.list.unshift(ite);
                      }else{
                        it.list.push(ite);
                      }
                    }
                  });
                });
              } else {
                item.list.map((it) => {
                  this.morelist.map((ite) => {
                    if (ite.chapter_id == it.id) {
                      if(val==0){
                        it.list.unshift(ite);
                      }else{
                        it.list.push(ite);
                      }
                    }
                  });
                });
              }
            });
            this.lessonData = this.data_chapters_list;
            this.lessonData.map((item) => {
              this.$set(item, "showFlag", true);
              item.list.forEach((ite) => {
                if (ite.title) {
                  this.$set(ite, "showFlagChild", true);
                  ite.list.map((it) => {
                    this.$set(it, "web_lesson_type", "lesson");
                  });
                }
              });
            });
          } else {
            this.lessonFlag = true;
            this.lessonData = this.data_lesson_list;
          }


        }
      });
    },
    getVidDur() {
     var  videoTime = document.getElementById("offcnplayer");
      this.initialimer = parseInt(videoTime.currentTime)
    },
    getend(){
      this.getreport()
    },
    getreport() {
      var video = document.getElementById("offcnplayer");
      this.watchtimer = video.currentTime;
      let params = {
        course_id: this.course_id,
        lesson_id: this.lesson_id,
        watchtimer: parseInt(this.watchtimer),
        initialimer: this.initialimer,
        client: 1,
      };
      report(params).then((res) => {
        if (res.code == 1) {
        }
      });
    },
    handleScroll(es) {
        let t = document.querySelector("#less" + es).offsetTop;
        document.querySelector('.listItemWrap').scrollTop=t-82
    },
    changeBg() {
      this.bgColor = !this.bgColor;
    },
    changeOpen() {
      this.openFlag = !this.openFlag;
    },
    showFeedbackDia() {
      this.$refs.FeedbakcDia.openInit();
    },
    showAssessDia() {
      this.$refs.CourseAssess.openInit();
    },
    showListItem(item) {
      item.showFlag = !item.showFlag;
      // if (!item.showFlag && item.children) {
      //   item.children.forEach((ite) => {
      //     if (ite.name) {
      //       ite.showFlagChild = true;
      //     }
      //   });
      // }
    },
    showListItemChild(ite) {
      ite.showFlagChild = !ite.showFlagChild;
    },
    showdiag() {
      this.$refs.ModelDia.dialogVisible = true;
    },
    jump(it) {
      // 1视频、2直播、3试卷、4资料、5作业、6问卷、7回放、8电子书、9签到
      if (this.modelFlag && it.over == 0) {
      } else {
        //视频  回放
        if (it.type == 1 || it.type == 7) {
          this.data_lesson_list = [];
          this.cateListNum = 0;
          this.data_chapters_list = [];
          this.course_id = it.course_id;
          this.lesson_id = it.id;
          this.getinfos(2);
          clearInterval(this.timer);
          this.timer = setInterval(this.getreport, 15000);
        }
        //直播中
        if (
          it.type == 2 &&
          it.live_sta_time - this.servertime < 0 &&
          it.live_end_time - this.servertime > 0
        ) {
        }
        //试卷
        if (it.type == 3) {
          //报告
          if (it.answer_id == 0 || it.answer_status == 0) {
            this.$router.push({
              path: "/pape_do",
              query: {
                area_type: 0,
                paper_id: it.media_id,
                answer_id: it.answer_id,
                course_id: it.course_id,
                lesson_id: it.id,
              },
            });
          }
          //继续作答
          if (it.answer_status == 1) {
            this.$router.push({
              path: "/paper_report",
              query: {
                answer_id: it.answer_id,
                paper_id: it.media_id,
              },
            });
          }
        }
        //资料-查看
        if (it.type == 4) {
          let params = {
            lesson_id: it.id,
            course_id: it.course_id,
          };
          downFile(params).then((res) => {
            if (res.code == 0) {
              window.location.href = res.data.downurl;
            }
          });
        }
        //ebook-查看
        if (it.type == 8) {
          let params = {
            lesson_id: it.id,
            course_id: it.course_id,
          };
          ebookinfo(params).then((res) => {
            if (res.code == 0) {
              window.open(res.data.downurl);
            }
          });
        }
        //作业
        if (it.type == 5) {
          this.$router.push({
            path: "/homework",
            query: {
              assignment_id: it.media_id,
              course_id: it.course_id,
              lesson_id: it.id,
            },
          });
        }
        //问卷
        if (it.type == 6) {
          if ( ( it.live_end_time&&this.servertime > it.live_end_time) ||
                  (it.live_sta_time&&it.live_sta_time > this.servertime)

          ) {} else {
            if (it.finished == 1) {
              this.$router.push({
                path: "/questionnaire_report",
                query: {
                  survey_id: it.media_id,
                  course_id: it.course_id,
                  answer_id: it.answer_id,
                  lesson_id: it.id
                },
              });
            }
            if (it.finished == 0) {
              this.$router.push({
                path: "/questionnaire",
                query: {
                  survey_id: it.media_id,
                  course_id: it.course_id,
                  answer_id: it.answer_id,
                  lesson_id: it.id
                },
              });
            }
          }
        }
      }
    },
  },
  destroyed() {
    this.timer = "";
  },
};
</script>

<style scoped lang="scss">
@import "./assets/video.scss";
.play{ position: absolute; left: 50% ; top: 50%; z-index: 10; margin: -60px 0 0 -220px;}
.liveWrap {
  position: absolute;
}
.colorBlue {
  color: #507fff;
}
.colorGreen {
  color: #42c9ab;
}
.colorRed {
  color: #fe4d67;
}
</style>
