<template>
    <div class="userWrap main">
        <div class="userMenuWrap">
            <ul>
                <li v-for="(item, index) in menuList" :key="index" :class="nowActive == item.name ? 'on' : ''"
                    @click="changeActive(item)">
                    <i class="iconfont" :class="item.icon"></i>{{ item.name }}
                </li>
            </ul>
        </div>
        <div class="uesrMainWrap">
            <router-view />
        </div>
    </div>
</template>

<script>
import { getCookie } from "../../../assets/js/cookie";

export default {
    watch: {
        $route: {
            handler: function (val) {
                if(val.meta.name=='订单详情'){
                    val.meta.name = '我的订单'
                }
                this.nowActive = val.meta.name;
            },
            deep: true,
        },
    },
    data () {
        return {
            menuList: [
                {
                    id: 1,
                    name: "个人信息",
                    icon: "icon-icon_jwxt",
                    path: "archives_student",
                },
                {
                    id: 6,
                    name: "我的订单",
                    icon: "icon-icon_wdxf",
                    path: "orderlist",
                },
                // {
                //   id: 2,
                //   name: "我的学分",
                //   icon: "icon-icon_wdxf",
                //   path: "credithour",
                // },
                // {
                //   id: 3,
                //   name: "我的证书",
                //   icon: "icon-icon_wdzs",
                //   path: "certificate",
                // },
                {
                    id: 4,
                    name: "反馈建议",
                    icon: "icon-icon_fkjy",
                    path: "feedback",
                },
                {
                    id: 5,
                    name: "账号设置",
                    icon: "icon-icon_zhsz",
                    path: "account",
                }
            ],
            nowActive: "",
        };
    },
    mounted () {
        this.$nextTick(() => {
            this.nowActive = this.$route.meta.name;
            if (this.nowActive == '订单详情') {
                this.nowActive = '我的订单'
            }
        })
        if (getCookie('siteid') == 52 || getCookie('siteid') == 56) {
            this.menuList.push({
                id: 6,
                name: "我的考试",
                icon: "icon-icon_zhsz",
                path: "MyExam",
            })
        }
    },
    methods: {
        changeActive (item) {
            this.nowActive = item.name;
            this.$router.push({
                path: item.path,
                meta: {
                    name: item.name,
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.userWrap {
    height: 100%;
    min-height: 600px;
    display: flex;
    margin-top: 20px;

    .userMenuWrap {
        width: 220px;
        height: 600px;
        padding: 20px 0;
        box-sizing: border-box;
        border-radius: 8px;
        background: #fff;
        margin-right: 20px;

        // position: fixed;
        li {
            height: 42px;
            line-height: 42px;
            padding-left: 30px;
            box-sizing: border-box;
            border-left: 3px solid transparent;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            &:hover {
                background: #F8F8F8;
                border-left: 3px solid var(--change-color);
                color: var(--change-color)
            }

            &.on {
                background: #F8F8F8;
                border-left: 3px solid var(--change-color);
                color: var(--change-color);
            }

            i {
                margin-right: 10px;
                font-size: 20px;
                width: 20px;
            }
        }
    }

    .uesrMainWrap {
        width: calc(100% - 240px);
        // padding-left: 240px;
        // box-sizing: border-box;
    }
}
</style>
