<template>
    <div>
        <el-dialog  :visible.sync="dialogVisible" width="30%" :before-close="handleClose" custom-class="stateClass">
            <span>支付后期开放</span>
            <span slot="footer" class="dialog-footer">
                <span @click="handleClose">我知道了</span>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dialogVisible:false
        }
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
        },
        openInit(){
            this.dialogVisible = true
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .stateClass{
    border-radius: 8px;
    .el-dialog__header{
        border:none !important;
    }
    .el-dialog__body{
        text-align: center;
        font-size:20px;
        color:#333;
        line-height:36px;
        padding: 30px 0 50px 0;
    }
    .el-dialog__footer{
        padding:0;
        text-align: center;
        border-top:1px solid #EEEEEE;
        font-size:16px;
        color:#507FFF;
        line-height:60px;
        span{
            cursor: pointer;
        }
    }
}
</style>