<template>
  <div :class="{ filters: expired }">
    <div
      style="
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0);
        width: 100%;
        height: 100%;
      "
      v-if="expired"
    ></div>
    <template v-if="lessonData.length">
      <div class="planWrap">
        <div class="modelWrap" v-if="modelFlag">
          <img src="../assets/images/cgms.png" alt="" />
          闯关模式
          <i class="iconfont icon-home_yjfk" @click="showdiag"></i>
        </div>
        <div
          class="livingWrap"
          v-if="livingList.length > 0 && !hideFlag && in_list != 0"
        >
          <div class="headerWrap">
            <span>{{ zb_title }}</span>
            <i class="el-icon-close" @click="hideLiving"></i>
          </div>

          <ul class="contentWrap">
            <li
              v-for="(item, index) in livingList"
              :key="index"
              :class="
                modelFlag && item.finished == 0
                  ? 'contentItemMask'
                  : 'contentItem'
              "
              @click="jump(item)"
            >
              <span class="leftItemNum">{{ item.number }}</span>
              <!-- <img src="../assets/images/s2.png" alt="" class="leftItemImg" /> -->
              <div class="rightItem">
                <div class="rightLeft">
                  <p class="leftTitle">{{ item.title }}</p>
                  <div class="leftInfo">
                    <span
                      >{{ item.live_sta_time | formatimes("hh:mm") }} -
                      {{ item.live_end_time | formatimes("hh:mm") }}</span
                    >
                  </div>
                </div>
                <div class="statusWrap">
                  <img src="@/assets/images/living_blue.gif" alt="" />
                  <div class="btnWrap">
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && item.finished == 0"
                    ></i>
                    进入直播
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="allWrap">
          <div class="cateWrap">
            <span
              class="cateItem"
              :class="nowActive == item.id ? 'on' : ''"
              v-for="(item, index) in cateList"
              :key="index"
              @click="changeCate(item.id)"
              >{{ item.name }}({{ item.num }})</span
            >
          </div>
          <!-- 课程 -->
          <ul v-if="lessonFlag">
            <li
              v-for="(it, i) in lessonData"
              :key="i"
              :class="
                modelFlag && it.over == 0 ? 'contentItemMask' : 'contentItem'
              "
            >
              <span class="leftItemNum">{{
                it.number < 10 ? "0" + it.number : it.number
              }}</span>
              <!-- <img
                :src="require('../assets/images/s' + it.type + '.png')"
                alt=""
                class="leftItemImg"
              /> -->
              <div class="rightItem">
                <div class="rightLeft">
                  <p class="leftTitle" v-if="it.title.length > 84">
                    <span
                      style="margin-right: 10px"
                      class="tag"
                      v-if="it.required == 1"
                      >必修</span
                    ><el-popover
                      placement="bottom"
                      width="680"
                      trigger="hover"
                      :content="it.title"
                    >
                      <em slot="reference">{{ it.title.substr(0, 84) }}…</em>
                    </el-popover>
                  </p>
                  <p class="leftTitle" v-else>
                    <span
                      style="margin-right: 10px"
                      class="tag"
                      v-if="it.required == 1"
                      >必修</span
                    >{{ it.title }}
                  </p>
                  <div class="leftInfo">
                    <span style="margin-right: 10px" class="tag">{{
                      it.media_name
                    }}</span>

                    <span
                      style="margin-right: 10px"
                      class="tag"
                      v-if="it.type == 4 && it.resources_type_name != ''"
                    >
                      {{ it.resources_type_name }}</span
                    >
                    <template v-if="it.type != 6">
                      <span v-if="it.live_sta_time != 0"
                        >{{
                          it.live_sta_time | formatimes("yyyy-MM-dd hh:mm")
                        }}
                        - {{ it.live_end_time | formatimes("hh:mm") }}</span
                      >
                    </template>
                    <template v-else>
                      <span
                        v-if="it.live_sta_time != 0 && it.live_end_time != 0"
                        >{{ it.live_sta_time | formatimes("yyyy-MM-dd hh:mm") }}
                        -
                        {{ it.live_end_time | formatimes("MM-dd hh:mm") }}</span
                      >
                      <span
                        v-if="it.live_sta_time != 0 && it.live_end_time == 0"
                        >{{ it.live_sta_time | formatimes("yyyy-MM-dd hh:mm") }}
                      </span>
                      <span
                        v-if="it.live_sta_time == 0 && it.live_end_time != 0"
                        >截至{{
                          it.live_end_time | formatimes("MM-dd hh:mm")
                        }}</span
                      >
                    </template>

                    <span v-if="it.media_length && it.type != 1"
                      >{{ it.media_length }}{{ it.type == 3 ? "道题" : "" }}
                    </span>
                    <span v-if="it.media_length && it.type == 1"
                      >{{ it.media_length }}
                    </span>
                    <span v-if="it.paper_time_limit && it.type == 3"
                      ><i class="iconfont icon-hsfz"></i>
                      {{ it.paper_time_limit }}
                    </span>
                    <span
                      v-if="it.finished == 1 && in_list != 0"
                      class="colorGreen"
                      ><i class="iconfont icon-yxw"></i> 已学完</span
                    >
                    <span
                      v-if="
                        it.finished == 0 &&
                        it.score &&
                        it.type != 3 &&
                        it.type != 5 &&
                        it.type != 9 &&
                        in_list != 0
                      "
                    >
                      <i class="iconfont icon-history"></i>已学{{ it.score }}
                    </span>
                  </div>
                </div>
                <!-- <div class="statusWrap" v-if="in_list">
                  <template
                    v-if="
                      it.type == 2 &&
                      it.live_sta_time - servertime < 0 &&
                      it.live_end_time - servertime > 0
                    "
                  >
                    <img src="@/assets/images/living_blue.gif" alt="" />
                    <div class="btnWrap">
                      <i
                        class="iconfont icon-maskLock"
                        v-if="modelFlag && it.over == 0"
                      ></i>
                      进入直播
                    </div>
                  </template>
                  <div class="btnWrap" v-if="it.type == 4">
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    查看
                  </div>
                  <div
                    class="btnWrap"
                    v-if="(it.type == 3 || it.type == 5) && it.answer_id == 0"
                  >
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    提交
                  </div>
                  <div
                    class="btnWrap"
                    v-if="
                      (it.type == 3 || it.type == 5) &&
                      it.answer_id != 0 &&
                      it.score == '' &&
                      in_list != 0
                    "
                  >
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    继续作答
                  </div>
                  <div
                    class="blueText"
                    v-if="
                      (it.type == 3 || it.type == 5) &&
                      it.answer_id != 0 &&
                      it.score &&
                      in_list != 0
                    "
                  >
                    {{ it.score }}
                  </div>
                  <div class="btnWrap" v-if="it.type == 9 && it.score">
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i
                    >已读完
                  </div>
                  <div class="btnWrap" v-if="it.type == 1">
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    播放视频
                  </div>
                  <div class="btnWrap" v-if="it.type == 7">
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    查看回放
                  </div>
                  <div
                    class="btnWrap"
                    v-if="
                      it.type == 2 &&
                      it.live_sta_time - servertime < 3600 &&
                      it.live_sta_time - servertime > 0
                    "
                  >
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    即将开始
                  </div>
                  <div
                    :class="modelFlag && it.over == 0 ? 'btnWrap' : 'grayText'"
                    v-if="it.type == 2 && it.live_sta_time - servertime > 3600"
                  >
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i
                    >直播未开始
                  </div>
                  <div
                    :class="modelFlag && it.over == 0 ? 'btnWrap' : 'grayText'"
                    v-if="it.type == 2 && servertime - it.live_end_time > 0"
                  >
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i
                    >直播已结束
                  </div>
                  <div class="btnWrap" v-if="it.type == 8">
                    <i
                      class="iconfont icon-maskLock"
                      v-if="modelFlag && it.over == 0"
                    ></i>
                    立即阅读
                  </div>
                  <template v-if="modelFlag && it.over == 0">
                    <div class="btnWrap" v-if="it.type == 6">
                      <i
                        class="iconfont icon-maskLock"
                        v-if="modelFlag && it.over == 0"
                      ></i
                      >{{
                        servertime > it.live_end_time
                          ? "已结束"
                          : it.live_sta_time > servertime
                          ? "未开始"
                          : it.finished
                          ? "查看"
                          : "提交"
                      }}
                    </div>
                  </template>
                  <template v-else>
                    <template
                      v-if="
                        it.type == 6 && it.live_end_time && it.live_sta_time
                      "
                    >
                      <div
                        class="btnWrap"
                        v-if="
                          it.live_end_time > servertime &&
                          servertime > it.live_sta_time
                        "
                      >
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >{{ it.finished ? "查看" : "提交" }}
                      </div>
                      <div
                        class="grayText"
                        v-if="
                          servertime > it.live_end_time ||
                          it.live_sta_time > servertime
                        "
                      >
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >{{
                          servertime > it.live_end_time
                            ? "已结束"
                            : it.live_sta_time > servertime
                            ? "未开始"
                            : ""
                        }}
                      </div>
                    </template>
                    <template
                      v-if="
                        it.type == 6 && !it.live_end_time && it.live_sta_time
                      "
                    >
                      <div class="btnWrap" v-if="servertime > it.live_sta_time">
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >{{ it.finished ? "查看" : "提交" }}
                      </div>
                      <div
                        class="grayText"
                        v-if="it.live_sta_time > servertime"
                      >
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >未开始
                      </div>
                    </template>
                    <template
                      v-if="
                        it.type == 6 && it.live_end_time && !it.live_sta_time
                      "
                    >
                      <div class="btnWrap" v-if="it.live_end_time > servertime">
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >{{ it.finished ? "查看" : "提交" }}
                      </div>
                      <div
                        class="grayText"
                        v-if="it.live_end_time < servertime"
                      >
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >已结束
                      </div>
                    </template>
                    <template
                      v-if="
                        it.type == 6 &&
                        it.live_end_time == 0 &&
                        it.live_sta_time == 0
                      "
                    >
                      <div class="btnWrap">
                        <i
                          class="iconfont icon-maskLock"
                          v-if="modelFlag && it.over == 0"
                        ></i
                        >{{ it.finished ? "查看" : "提交" }}
                      </div>
                    </template>
                  </template>
                </div> -->
              </div>
            </li>
          </ul>
          <!-- 有章节 -->
          <ul class="listItemWrap" v-else>
            <li
              class="listItem"
              v-for="(item, index) in lessonData"
              :key="index"
            >
              <div
                class="itemTitle"
                @click="showListItem(item)"
                v-if="item.showInfo"
              >
                <div class="titleLeft">
                  <i class="circle"></i>
                  <span
                    ><i class="titleLefti">第 {{ index + 1 }} 章</i
                    >{{ item.title }}</span
                  >
                </div>
                <i class="arrow el-icon-arrow-up" v-if="item.showFlag"></i>
                <i class="arrow el-icon-arrow-down" v-else></i>
              </div>
              <template v-if="item.showFlag">
                <div
                  class="itemContent"
                  v-for="(ite, ind) in item.list"
                  :key="ind"
                >
                  <div
                    class="contentTitle"
                    v-if="ite.title && ite.list.length"
                    @click="showListItemChild(ite)"
                  >
                    <p>
                      <i class="titleLefti">第 {{ ind + 1 }} 节 </i
                      >{{ ite.title }}
                    </p>
                    <i
                      class="arrow el-icon-arrow-up"
                      v-if="ite.showFlagChild"
                    ></i>
                    <i class="arrow el-icon-arrow-down" v-else></i>
                  </div>
                  <template v-if="ite.showFlagChild || !ite.title">
                    <ul>
                      <li
                        v-for="(it, i) in ite.list"
                        :key="i"
                        :class="
                          modelFlag && it.over == 0
                            ? 'contentItemMask'
                            : 'contentItem'
                        "
                        @click="jump(it)"
                      >
                        <span class="leftItemNum">{{
                          it.number < 10 ? "0" + it.number : it.number
                        }}</span>
                        <!-- <img
                          :src="
                            require('../assets/images/s' + it.type + '.png')
                          "
                          alt=""
                          class="leftItemImg"
                        /> -->
                        <div class="rightItem">
                          <div class="rightLeft">
                            <p class="leftTitle">
                              <span
                                style="margin-right: 10px"
                                class="tag"
                                v-if="it.required == 1"
                                >必修</span
                              >{{ it.title }}
                            </p>
                            <div class="leftInfo">
                              <span style="margin-right: 10px" class="tag">{{
                                it.media_name
                              }}</span>
                              <span
                                style="margin-right: 10px"
                                class="tag"
                                v-if="
                                  it.type == 4 && it.resources_type_name != ''
                                "
                              >
                                {{ it.resources_type_name }}</span
                              >
                              <template v-if="it.type != 6">
                                <span v-if="it.live_sta_time != 0"
                                  >{{
                                    it.live_sta_time
                                      | formatimes("yyyy-MM-dd hh:mm")
                                  }}
                                  -
                                  {{
                                    it.live_end_time | formatimes("hh:mm")
                                  }}</span
                                >
                              </template>
                              <template v-else>
                                <span
                                  v-if="
                                    it.live_sta_time != 0 &&
                                    it.live_end_time != 0
                                  "
                                  >{{
                                    it.live_sta_time
                                      | formatimes("yyyy-MM-dd hh:mm")
                                  }}
                                  -
                                  {{
                                    it.live_end_time | formatimes("MM-dd hh:mm")
                                  }}</span
                                >
                                <span
                                  v-if="
                                    it.live_sta_time != 0 &&
                                    it.live_end_time == 0
                                  "
                                  >{{
                                    it.live_sta_time
                                      | formatimes("yyyy-MM-dd hh:mm")
                                  }}
                                </span>
                                <span
                                  v-if="
                                    it.live_sta_time == 0 &&
                                    it.live_end_time != 0
                                  "
                                  >截至{{
                                    it.live_end_time | formatimes("MM-dd hh:mm")
                                  }}</span
                                >
                              </template>
                              <span v-if="it.media_length && it.type != 1"
                                >{{ it.media_length
                                }}{{ it.type == 3 ? "道题" : "" }}
                              </span>
                              <span v-if="it.media_length && it.type == 1"
                                >{{ it.media_length }}
                              </span>
                              <span v-if="it.paper_time_limit && it.type == 3"
                                ><i class="iconfont icon-hsfz"></i>
                                {{ it.paper_time_limit }}
                              </span>
                              <span
                                v-if="it.finished == 1 && in_list != 0"
                                class="colorGreen"
                                ><i class="iconfont icon-yxw"></i> 已学完</span
                              >
                              <span
                                v-if="
                                  it.finished == 0 &&
                                  it.score &&
                                  it.type != 3 &&
                                  it.type != 5 &&
                                  it.type != 9 &&
                                  in_list != 0
                                "
                              >
                                <i class="iconfont icon-history"></i>已学{{
                                  it.score
                                }}
                              </span>
                            </div>
                          </div>
                          <div class="statusWrap" v-if="in_list">
                            <template
                              v-if="
                                it.type == 2 &&
                                it.live_sta_time - servertime < 0 &&
                                it.live_end_time - servertime > 0
                              "
                            >
                              <img
                                src="@/assets/images/living_blue.gif"
                                alt=""
                              />
                              <div class="btnWrap">
                                <i
                                  class="iconfont icon-maskLock"
                                  v-if="modelFlag && it.over == 0"
                                ></i>
                                进入直播
                              </div>
                            </template>
                            <div class="btnWrap" v-if="it.type == 4">
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i>
                              查看
                            </div>
                            <div
                              class="btnWrap"
                              v-if="
                                (it.type == 3 || it.type == 5) &&
                                it.answer_id == 0
                              "
                            >
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i>
                              提交
                            </div>
                            <div
                              class="btnWrap"
                              v-if="
                                (it.type == 3 || it.type == 5) &&
                                it.answer_id != 0 &&
                                it.score == '' &&
                                in_list != 0
                              "
                            >
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i>
                              继续作答
                            </div>
                            <div
                              class="blueText"
                              v-if="
                                (it.type == 3 || it.type == 5) &&
                                it.answer_id != 0 &&
                                it.score
                              "
                            >
                              {{ it.score }}
                            </div>
                            <div
                              class="btnWrap"
                              v-if="it.type == 9 && it.score"
                            >
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0 && in_list != 0"
                              ></i
                              >已读完
                            </div>
                            <div class="btnWrap" v-if="it.type == 1">
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0 && i != 0"
                              ></i>
                              播放视频
                            </div>
                            <div class="btnWrap" v-if="it.type == 7">
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i>
                              查看回放
                            </div>
                            <div
                              class="btnWrap"
                              v-if="
                                it.type == 2 &&
                                it.live_sta_time - servertime < 3600 &&
                                it.live_sta_time - servertime > 0
                              "
                            >
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i>
                              即将开始
                            </div>
                            <div
                              :class="
                                modelFlag && it.over == 0
                                  ? 'btnWrap'
                                  : 'grayText'
                              "
                              v-if="
                                it.type == 2 &&
                                it.live_sta_time - servertime > 3600
                              "
                            >
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i
                              >直播未开始
                            </div>
                            <div
                              :class="
                                modelFlag && it.over == 0
                                  ? 'btnWrap'
                                  : 'grayText'
                              "
                              v-if="
                                it.type == 2 &&
                                servertime - it.live_end_time > 0
                              "
                            >
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i
                              >直播已结束
                            </div>
                            <div class="btnWrap" v-if="it.type == 8">
                              <i
                                class="iconfont icon-maskLock"
                                v-if="modelFlag && it.over == 0"
                              ></i>
                              立即阅读
                            </div>
                            <template v-if="it.type == 6 && it.finished == 1">
                              <div class="btnWrap">查看</div>
                            </template>
                            <template v-else>
                              <template v-if="modelFlag && it.over == 0">
                                <div class="btnWrap" v-if="it.type == 6">
                                  <i
                                    class="iconfont icon-maskLock"
                                    v-if="modelFlag && it.over == 0"
                                  ></i
                                  >{{
                                    servertime > it.live_end_time
                                      ? "已结束"
                                      : it.live_sta_time > servertime
                                      ? "未开始"
                                      : it.finished
                                      ? "查看"
                                      : "提交"
                                  }}
                                </div>
                              </template>
                              <template v-else>
                                <template
                                  v-if="
                                    it.type == 6 &&
                                    it.live_end_time &&
                                    it.live_sta_time
                                  "
                                >
                                  <div
                                    class="btnWrap"
                                    v-if="
                                      it.live_end_time > servertime &&
                                      servertime > it.live_sta_time
                                    "
                                  >
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >{{ it.finished ? "查看" : "提交" }}
                                  </div>
                                  <div
                                    class="grayText"
                                    v-if="
                                      servertime > it.live_end_time ||
                                      it.live_sta_time > servertime
                                    "
                                  >
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >{{
                                      servertime > it.live_end_time
                                        ? "已结束"
                                        : it.live_sta_time > servertime
                                        ? "未开始"
                                        : ""
                                    }}
                                  </div>
                                </template>
                                <template
                                  v-if="
                                    it.type == 6 &&
                                    !it.live_end_time &&
                                    it.live_sta_time
                                  "
                                >
                                  <div
                                    class="btnWrap"
                                    v-if="servertime > it.live_sta_time"
                                  >
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >{{ it.finished ? "查看" : "提交" }}
                                  </div>
                                  <div
                                    class="grayText"
                                    v-if="it.live_sta_time > servertime"
                                  >
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >未开始
                                  </div>
                                </template>
                                <template
                                  v-if="
                                    it.type == 6 &&
                                    it.live_end_time &&
                                    !it.live_sta_time
                                  "
                                >
                                  <div
                                    class="btnWrap"
                                    v-if="it.live_end_time > servertime"
                                  >
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >{{ it.finished ? "查看" : "提交" }}
                                  </div>
                                  <div
                                    class="grayText"
                                    v-if="it.live_end_time < servertime"
                                  >
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >已结束
                                  </div>
                                </template>
                                <template
                                  v-if="
                                    it.type == 6 &&
                                    it.live_end_time == 0 &&
                                    it.live_sta_time == 0
                                  "
                                >
                                  <div class="btnWrap">
                                    <i
                                      class="iconfont icon-maskLock"
                                      v-if="modelFlag && it.over == 0"
                                    ></i
                                    >{{ it.finished ? "查看" : "提交" }}
                                  </div>
                                </template>
                              </template></template
                            >
                          </div>
                        </div> 
                      </li>
                    </ul>
                  </template>
                </div>
              </template>
            </li>
          </ul>
        </div>
        <!--        <p class="moreBtn" @click="getMore" v-if="lessonData.length > 0">-->
        <!--          <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}-->
        <!--        </p>-->
        <ModelDia ref="ModelDia"></ModelDia>
      </div>
    </template>
    <template v-else>
      <div class="noDataWrap">
        <img src="@/assets/images/noData.png" alt="" />
        <p>暂无课程安排</p>
      </div>
    </template>
  </div>
</template>

<script>
import ModelDia from "@/views/components/modelDia/modelDia";
import {
  courseInfo,
  lessonList,
  downFile,
  ebookinfo,
  liveinfo,
} from "../assets/api.js";
export default {
  props: ["modelFlag", "expired"],
  components: {
    ModelDia,
  },
  data() {
    return {
      in_list: 0,
      lessonList: [],
      livingList: [],
      cateList: [],
      cateNum: [],
      nowActive: 0,
      lessonFlag: true,
      listData: [],
      lessonData: [],
      hideFlag: false,
      servertime: "",
      allListList: [],
      data_lesson_list: [],
      data_chapters_list: [],
      page: 1,
      limit: 20,
      btnMessage: "加载更多",
      cateListNum: 0,
      zb_title: "",
      morelist: [],
    };
  },
  mounted() {
    this.getLessons();
    this.getcourseInfo();
    this.in_list = JSON.parse(sessionStorage.getItem("course")).in_list;
  },
  methods: {
    getcourseInfo() {
      let params = {
        course_id: this.$route.query.course_id,
      };
      courseInfo(params).then((res) => {
        if (res.code == 0) {
          this.in_list = res.data.in_list;
        }
      });
    },
    getLessons() {
      this.cateListNum++;
      let params = {
        course_id: this.$route.query.course_id,
        lesson_type: this.nowActive,
        page: this.page,
        limit: this.limit,
      };
      lessonList(params).then((res) => {
        if (res.code == 0) {
          this.servertime = res.data.servertime;
          if (res.data.lesson_list.length != 0) {
            this.morelist = res.data.lesson_list;
            this.data_lesson_list = res.data.lesson_list;
          } //else {
          //   this.morelist = [];
          //   this.btnMessage = "没有更多";
          // }
          if (this.cateListNum == 1) {
            if (res.data.chapters_list.length) {
              this.data_chapters_list = res.data.chapters_list;
            }
          }

          this.data_lesson_list.map((item) => {
            if (item.type == 6 && item.live_end_time < this.servertime) {
              this.$set(item, "over", 1);
            } else {
              this.$set(item, "over", item.finished);
            }
            // this.$set(item, "over", item.finished);
          });
          // console.log(this.data_lesson_list,23233)
          var x;
          if (this.data_lesson_list.length > 0) {
            x = this.data_lesson_list.findIndex((item) => item.over == 0);
            if (x != -1) {
              this.data_lesson_list[x].over = 1;
            } else {
              this.data_lesson_list[0].over = 0;
            }
          }
          this.data_lesson_list.map((item, index) => {
            if (index > x) {
              this.$set(item, "over", 0);
            }
          });
          if (this.data_chapters_list.length > 0) {
            this.lessonFlag = false;
            this.data_chapters_list.map((item) => {
              if (item.list.length == 0) {
                item.list.push({ id: item.id, list: [], title: "" });
                item.list.map((it) => {
                  this.morelist.map((ite) => {
                    if (ite.chapter_id == it.id) {
                      it.list.push(ite);
                    }
                  });
                });
              } else {
                item.list.map((it) => {
                  this.morelist.map((ite) => {
                    if (ite.chapter_id == it.id) {
                      it.list.push(ite);
                    }
                  });
                });
              }
            });
            this.lessonData = this.data_chapters_list;
            this.lessonData.map((item) => {
              this.$set(item, "showFlag", true);

              item.list.forEach((ite) => {
                if (ite.title) {
                  this.$set(ite, "showFlagChild", true);
                  ite.list.map((it) => {
                    this.$set(it, "web_lesson_type", "lesson");
                  });
                }
              });
              let a = item.list.every((ite) => ite.list.length == 0);
              this.$set(item, "showInfo", !a);
            });
          } else {
            this.lessonFlag = true;
            this.lessonData = this.data_lesson_list;
          }
          this.allListList = JSON.parse(JSON.stringify(this.lessonData));
          this.livingList = res.data.zb_list;
          this.zb_title = res.data.title;
          if (this.cateListNum == 1) {
            //1视频 2直播 3试卷 4资料 5作业 6问卷 7回放 8电子书 9签到
            let num =
              res.data.course_num_count.video_num +
              res.data.course_num_count.live_num +
              res.data.course_num_count.paper_num +
              res.data.course_num_count.material_num +
              res.data.course_num_count.assignment_num +
              res.data.course_num_count.survey_num +
              res.data.course_num_count.ebook_num;
            if (num) {
              this.cateList.push({
                id: 0,
                name: "全部",
                num: num,
              });
            }
            if (res.data.course_num_count.video_num) {
              this.cateList.push({
                id: 1,
                name: "视频",
                num: res.data.course_num_count.video_num,
              });
            }
            if (res.data.course_num_count.live_num) {
              this.cateList.push({
                id: 2,
                name: "直播",
                num: res.data.course_num_count.live_num,
              });
            }
            if (res.data.course_num_count.paper_num) {
              this.cateList.push({
                id: 3,
                name: "试卷",
                num: res.data.course_num_count.paper_num,
              });
            }
            if (res.data.course_num_count.material_num) {
              this.cateList.push({
                id: 4,
                name: "资料",
                num: res.data.course_num_count.material_num,
              });
            }
            if (res.data.course_num_count.assignment_num) {
              this.cateList.push({
                id: 5,
                name: "作业",
                num: res.data.course_num_count.assignment_num,
              });
            }
            if (res.data.course_num_count.survey_num) {
              this.cateList.push({
                id: 6,
                name: "问卷",
                num: res.data.course_num_count.survey_num,
              });
            }
            // if (res.data.course_num_count.video_num) {
            //   this.cateList.push({
            //     id: 7,
            //     name: "回放",
            //     num: res.data.course_num_count.video_num,
            //   });
            // }
            if (res.data.course_num_count.ebook_num) {
              this.cateList.push({
                id: 8,
                name: "电子书",
                num: res.data.course_num_count.ebook_num,
              });
            }
          }
        }
      });
    },
    changeCate(id) {
      this.nowActive = id;
      if (id == 0) {
        this.getLessons();
      } else {
        this.lessonFlag = true;
        let arr = [];
        this.morelist.map((item) => {
          if (item.type == id) {
            arr.push(item);
          }
        });
        this.lessonData = arr;
      }
    },
    showListItem(item) {
      item.showFlag = !item.showFlag;
    },
    showListItemChild(ite) {
      ite.showFlagChild = !ite.showFlagChild;
    },
    hideLiving() {
      this.hideFlag = true;
    },
    // getMore() {
    //   if (this.btnMessage == "加载更多") {
    //     this.page++;
    //     this.getLessons();
    //   }
    // },
    showdiag() {
      this.$refs.ModelDia.dialogVisible = true;
    },
    jump(it) {
      if (this.in_list == 0) {
        return;
      } else {
        // 1视频、2直播、3试卷、4资料、5作业、6问卷、7回放、8电子书、9签到
        if (this.modelFlag && it.over == 0) {
        } else {
          //视频  回放
          if (it.type == 1 || it.type == 7) {
            let win = window.location.host;
            window.open(
              "//" +
                win +
                "/student/#/video?course_id=" +
                it.course_id +
                "&lesson_id=" +
                it.id +
                "&modelFlag=" +
                this.modelFlag
            );
            // this.$router.push({
            //   path: "/video",
            //   query: {
            //     course_id: it.course_id,
            //     lesson_id: it.id,
            //     modelFlag: this.modelFlag,
            //   },
            // });
          }
          //直播中
          if (it.type == 2 && it.live_sta_time - this.servertime < 0) {
            let params = {
              course_id: it.course_id,
              lesson_id: it.id,
            };
            liveinfo(params).then((res) => {
              if (res.code == 0) {
                window.open(
                  "https://live.offcn.com/third_occchat?cid=" +
                    res.data.channel_id +
                    "&map=" +
                    res.data.map +
                    "&realkey=" +
                    res.data.realkey +
                    "&loginkey=1"
                );
              }
            });
          }
          //试卷
          if (it.type == 3) {
            //继续作答
            if (it.answer_id == 0 || it.answer_status == 0) {
              this.$router.push({
                path: "/pape_do",
                query: {
                  area_type: 0,
                  paper_id: it.media_id,
                  answer_id: it.answer_id,
                  course_id: it.course_id,
                  lesson_id: it.id,
                },
              });
            }
            //报告
            if (it.answer_status == 1) {
              this.$router.push({
                path: "/paper_report",
                query: {
                  answer_id: it.answer_id,
                  paper_id: it.media_id,
                },
              });
            }
          }
          //资料-查看
          if (it.type == 4) {
            let params = {
              lesson_id: it.id,
              course_id: it.course_id,
            };
            downFile(params).then((res) => {
              if (res.code == 0) {
                window.open(res.data.downurl);
              }
            });
          }
          //ebook-查看
          if (it.type == 8) {
            let params = {
              lesson_id: it.id,
              course_id: it.course_id,
            };
            ebookinfo(params).then((res) => {
              if (res.code == 0) {
                window.open(res.data.downurl);
              }
            });
          }
          //作业
          if (it.type == 5) {
            this.$router.push({
              path: "/homework",
              query: {
                assignment_id: it.media_id,
                course_id: it.course_id,
                lesson_id: it.id,
              },
            });
          }
          //问卷
          if (it.type == 6) {
            if (
              (it.live_end_time && this.servertime > it.live_end_time) ||
              (it.live_sta_time && it.live_sta_time > this.servertime)
            ) {
            } else {
              if (it.finished == 1) {
                this.$router.push({
                  path: "/questionnaire_report",
                  query: {
                    survey_id: it.media_id,
                    course_id: it.course_id,
                    answer_id: it.answer_id,
                    lesson_id: it.id,
                  },
                });
              }
              if (it.finished == 0) {
                this.$router.push({
                  path: "/questionnaire",
                  query: {
                    survey_id: it.media_id,
                    course_id: it.course_id,
                    answer_id: it.answer_id,
                    lesson_id: it.id,
                  },
                });
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.noDataWrap {
  text-align: center;
  padding: 100px 113px;
  box-sizing: border-box;
  p {
    margin: 25px 0;
    font-size: 16px;
    color: #999999;
  }
}
.filters {
  filter: grayscale(100%);
  position: relative;
}
.planWrap {
  padding: 30px 40px;
  box-sizing: border-box;
  .modelWrap {
    margin-top: -20px;
    font-size: 20px;
    color: #333;
    img {
      margin-right: 10px;
    }
    i {
      font-size: 18px;
      color: #aeaeae;
    }
  }
  .livingWrap {
    background: #f8faff;
    border-radius: 6px;
    margin-bottom: 30px;
    .headerWrap {
      padding: 15px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-bottom: 1px solid #eaedf3;
      i {
        cursor: pointer;
      }
    }
    .contentWrap {
      padding: 15px 0;
      box-sizing: border-box;
      .contentItem {
        padding: 15px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // cursor: pointer;
        .leftItemNum {
          font-size: 20px;
          font-weight: bold;
          color: #b9bcc1;
          margin-right: 15px;
          width: 52px;
        }
        .leftItemImg {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
        .rightItem {
          width: calc(100% - 102px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rightLeft {
            padding-right: 65px;
            box-sizing: border-box;
            .leftTitle {
              font-size: 16px;
              margin-bottom: 10px;
              .tag {
                display: inline-block;
                border-radius: 4px;
                line-height: 19px;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #ffa7a7;
                color: #fe4e00;
                font-size: 14px;
              }
            }
            .leftInfo {
              font-size: 14px;
              color: #999999;
              .tag {
                border-radius: 4px;
                line-height: 19px;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #d8d8d8;
              }
            }
          }
          .statusWrap {
            display: flex;
            align-items: center;
            img {
              margin-right: 15px;
            }
            .btnWrap {
              width: 96px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              background:#fff;border:1px solid var(--change-color);
              color:  var(--change-color);
              border-radius: 16px;
              &:hover {
                background:  var(--change-color);
                color: #fff;
              }
            }
          }
        }
        &:hover {
          .leftTitle {
            // color: var(--change-color) ;
          }
        }
      }
      .contentItemMask {
        padding: 15px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: not-allowed;
        color: #999;
        .leftItemNum {
          font-size: 20px;
          font-weight: bold;
          color: #b9bcc1;
          margin-right: 15px;
          width: 52px;
        }
        .leftItemImg {
          width: 40px;
          height: 40px;
          margin-right: 15px;
          -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
          filter: grayscale(100%);
        }
        .rightItem {
          width: calc(100% - 102px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rightLeft {
            padding-right: 65px;
            box-sizing: border-box;
            .leftTitle {
              font-size: 16px;
              margin-bottom: 10px;
              color: #999999;
              .tag {
                display: inline-block;
                border-radius: 4px;
                line-height: 19px;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #ffa7a7;
                color: #fe4e00;
                font-size: 14px;
              }
            }
            .leftInfo {
              font-size: 14px;
              color: #999999;
              .tag {
                border-radius: 4px;
                line-height: 19px;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #d8d8d8;
              }
            }
          }
          .statusWrap {
            display: flex;
            align-items: center;
            img {
              margin-right: 15px;
            }
            .btnWrap {
              width: 96px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              background: rgba(0, 0, 0, 0.2);
              color: #999;
              border-radius: 16px;
              position: relative;
              i {
                color: #fff;
                font-size: 16px;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -8px;
                margin-top: -15px;
              }
            }
          }
        }
      }
    }
  }
  .allWrap {
    box-sizing: border-box;
    margin-top: 10px;
    .cateWrap {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .cateItem {
        height: 36px;
        line-height: 36px;
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
        border-radius: 18px;
        color: #333;
        border: 1px solid #f4f4f4;
        background: #f4f4f4;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 10px;
        &:hover {
          color: var(--change-color);
        }
      }
      .on {
        color: var(--change-color);
        background:#fff;border:1px solid var(--change-color);
      }
    }
    .contentItem {
      padding: 15px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    //   cursor: pointer;
      .leftItemNum {
        font-size: 20px;
        font-weight: bold;
        color: #b9bcc1;
        margin-right: 15px;
        width: 52px;
      }
      .leftItemImg {
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }
      .rightItem {
        width: calc(100% - 102px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rightLeft {
          padding-right: 65px;
          box-sizing: border-box;
          .leftTitle {
            font-size: 16px;
            margin-bottom: 10px;
            .tag {
              display: inline-block;
              border-radius: 4px;
              line-height: 19px;
              padding: 0 6px;
              box-sizing: border-box;
              border: 1px solid #ffa7a7;
              color: #fe4e00;
              font-size: 14px;
            }
          }
          .leftInfo {
            font-size: 14px;
            color: #999999;
            .tag {
              border-radius: 4px;
              line-height: 19px;
              padding: 0 6px;
              box-sizing: border-box;
              border: 1px solid #d8d8d8;
            }
          }
        }
        .statusWrap {
          display: flex;
          align-items: center;
          img {
            margin-right: 15px;
          }
          .btnWrap {
            width: 96px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background:#fff;border:1px solid var(--change-color);
            color: var(--change-color);
            border-radius: 16px;
            font-size: 14px;
            &:hover {
              background: var(--change-color);
              color: #fff;
            }
          }
          .grayText {
            width: 96px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #999999;
          }
          .blueText {
            width: 96px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: var(--change-color);
          }
        }
      }
      &:hover {
        .leftTitle {
        //   color: var(--change-color);
        }
      }
      .colorBlue {
        color: #507fff;
      }
      .colorGreen {
        color: #42c9ab;
      }
      .colorRed {
        color: #fe4d67;
      }
    }
    .contentItemMask {
      padding: 15px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: not-allowed;
      color: #999;
      .leftItemNum {
        font-size: 20px;
        font-weight: bold;
        color: #b9bcc1;
        margin-right: 15px;
        width: 52px;
      }
      .leftItemImg {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
      }
      .rightItem {
        width: calc(100% - 102px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rightLeft {
          padding-right: 65px;
          box-sizing: border-box;
          .leftTitle {
            font-size: 16px;
            margin-bottom: 10px;
            color: #999999;
            .tag {
              display: inline-block;
              border-radius: 4px;
              line-height: 19px;
              padding: 0 6px;
              box-sizing: border-box;
              border: 1px solid #ffa7a7;
              color: #fe4e00;
              font-size: 14px;
            }
          }
          .leftInfo {
            font-size: 14px;
            color: #999999;
            .tag {
              border-radius: 4px;
              line-height: 19px;
              padding: 0 6px;
              box-sizing: border-box;
              border: 1px solid #d8d8d8;
            }
          }
        }
        .statusWrap {
          display: flex;
          align-items: center;
          img {
            margin-right: 15px;
          }
          .btnWrap {
            width: 96px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: rgba(0, 0, 0, 0.2);
            color: #999;
            border-radius: 16px;
            position: relative;
            font-size: 14px;
            i {
              color: #fff;
              font-size: 16px;
              position: absolute;
              left: 50%;
              top: 50%;
              margin-left: -8px;
              margin-top: -15px;
            }
          }
          .grayText {
            width: 96px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #999999;
          }
          .blueText {
            width: 96px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #999999;
          }
        }
      }
      .colorBlue {
        color: #999999;
      }
      .colorGreen {
        color: #999999;
      }
      .colorRed {
        color: #999999;
      }
    }
    .listItemWrap {
      .listItem {
        margin-top: 15px;
        .titleLefti {
          padding-right: 10px;
        }
        .itemTitle {
          padding: 18px 25px;
          box-sizing: border-box;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #f8faff;
          border-radius: 6px;
          cursor: pointer;
          .titleLeft {
            display: flex;
            align-items: center;

            .circle {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              border: 2px solid var(--default-color);
              margin-right: 25px;
            }
          }
        }
        .itemContent {
          margin-top: 15px;
          .contentTitle {
            padding: 18px 25px 18px 62px;
            box-sizing: border-box;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.moreBtn {
  margin: 0 auto;
  margin-top: 30px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #666;
  &:hover {
    background: var(--change-color);
    border: 1px solid var(--change-color);
    color: #fff;
  }
  i {
    font-size: 14px;
  }
}
</style>
