var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.footerFlag ? '' : 'h100',staticStyle:{"height":"100%"}},[_c('div',{class:_vm.$route.path == '/video' || _vm.$route.path == '/videoplay'
        ? 'h100'
        : '',attrs:{"id":"app"}},[_c('router-view'),(
            _vm.$route.path != '/video' &&
            _vm.$route.path != '/videoplay' &&
            _vm.$route.path != '/newlearn'
        )?[(!_vm.xiaomiFlag)?_c('ul',{staticClass:"homeWrapright"},[(_vm.nav_show)?_c('li',{staticStyle:{"border-bottom":"none","padding-bottom":"0"},on:{"click":_vm.backTop}},[_c('i',{staticClass:"iconfont icon-home_top"})]):_vm._e()]):_vm._e()]:_vm._e()],2),(_vm.footerFlag && _vm.siteid != 46 && _vm.siteid != 68)?_c('div',{staticClass:"footr",class:_vm.$route.path == '/notice_detail' ||
        _vm.$route.path == '/noticeDetail'
        ? 'posiFooter'
        : ''},[_vm._v(" Copyright©1999-2023 北京中公教育科技有限公司 .All Rights Reserved 　"),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","rel":"noopener noreferrer nofollow","target":"_blank"}},[_vm._v("京ICP备10218183号-115 ")]),_vm._v("　京ICP证161188号　"),_vm._m(0),_vm._m(1),_vm._v(" 友情链接 "),_c('a',{attrs:{"target":"_blank","rel":"nofollow","href":"https://www.pku.edu.cn/"}},[_vm._v("北京大学")]),_vm._v(" | "),_c('a',{attrs:{"target":"_blank","rel":"nofollow","href":"https://www.ipku.com/"}},[_vm._v("北大学堂")])]):_vm._e(),(_vm.footerFlag && _vm.siteid == 46)?_c('div',{staticClass:"footr",class:_vm.$route.path == '/notice_detail' ||
        _vm.$route.path == '/noticeDetail'
        ? 'posiFooter'
        : ''},[_vm._v(" Copyright©1999-2024 北京中公教育科技有限公司 .All Rights Reserved 　"),_c('a',{attrs:{"href":"http://beian.miit.gov.cn/#/Integrated/index","rel":"noopener noreferrer nofollow","target":"_blank"}},[_vm._v("京ICP备10218183号-119 ")]),_vm._v("　京ICP证161188号　"),_vm._m(2),_vm._m(3),_vm._v(" 友情链接 "),_c('a',{attrs:{"target":"_blank","rel":"nofollow","href":"https://www.pku.edu.cn/"}},[_vm._v("北京大学")]),_vm._v(" | "),_c('a',{attrs:{"target":"_blank","rel":"nofollow","href":"https://www.ipku.com/"}},[_vm._v("北大学堂")])]):_vm._e(),(_vm.footerFlag && _vm.siteid == 68)?_c('div',{staticClass:"footr",class:_vm.$route.path == '/notice_detail' ||
            _vm.$route.path == '/noticeDetail'
            ? 'posiFooter'
            : ''},[_vm._v(" Copyright 版权所有©北京大学智能学院 All Rrights Reserved. ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"target":"_blank","rel":"nofollow","href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033985"}},[_c('img',{attrs:{"src":"https://www.offcn.com/statics/images/zgimg/gongbei.png","alt":""}}),_vm._v(" 京公网安备 11010802033985号　")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91110108551426892J-SAIC_SHOW_10000091110108551426892J1597915417751&signData=MEUCIH9M0BO8PQFb+MVcIITB7ZpQQLI7g0H9y2TpkODrxFmfAiEApST5zov2Xc1mpEmCDBQekHDo0dl1ZfgoxsJWr8OFfSU=","target":"_blank","rel":"nofollow"}},[_c('img',{attrs:{"src":"https://www.offcn.com/statics/images/zgimg/dzzz.png","alt":""}}),_vm._v(" 电子营业执照")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"target":"_blank","rel":"nofollow","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=%2011010802033494"}},[_c('img',{attrs:{"src":"https://www.offcn.com/statics/images/zgimg/gongbei.png","alt":""}}),_vm._v(" 京公网安备 11010802033494号　")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91110108551426892J-SAIC_SHOW_10000091110108551426892J1606892638221&signData=MEQCIHzkQgiy0M58Y5zRRPz3FnbtREGke1rU+QXRB1yaYhedAiAXoSZR/KzuNMH7h4KubDLwwlCeye4n5QAdochM7WCLdg==","target":"_blank","rel":"nofollow"}},[_c('img',{attrs:{"src":"https://www.offcn.com/statics/images/zgimg/dzzz.png","alt":""}}),_vm._v(" 电子营业执照")])}]

export { render, staticRenderFns }