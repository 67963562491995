import { get, post } from '../../../../api/index';

export const courseInfo = params => get(`/api/course/v1/detail/courseInfo`, params); // 课程详情
export const activityList = params => get(`/api/teaching/v1/activity/list`, params); // 教学活动列表
export const activityInfo = params => get(`/api/teaching/v1/activity/info`, params); // 教学活动签到
export const paperInfo = params => get(`/api/teaching/v1/papers/info`, params); // 查看随堂测验活动
export const paperSubmit = params => post(`/api/teaching/v1/papers/submit`, params); // 提交学员作答
export const notificalist = params => get(`/api/notification/v1/usernotifilist`, params); // 通知列表
export const notifilistnum = params => get(`/api/notification/v1/notifilistnum`, params); // 通知总数
export const notinfo = params => get(`/api/notification/v1/info`, params); // 通知详情
// export const quizInfo = params => post(`/api/admin/teaching/v1/activity/question/list`, params); // 随堂测验详情

export const homeList = params => get(`/api/assignment/v1/list`, params); // 作业列表
export const assignmentmain = params => get(`/api/assignment/v1/assignmentmain`, params); //作业温馨提示


export const newpaperList = params => get(`/api/newpaper/v1/list`, params); // 考试列表
export const achievementInfo = params => get(`/api/course/v1/achievement/info`, params); //课程成绩

export const courseLearn = params => get(`/api/course/v1/portal/course_learn`, params);  // 课程学习页
