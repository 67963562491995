import Vue from "vue";
import VueRouter from "vue-router";

import { home } from "@p/home/assets/home.js"; //首页
import { notice } from "@p/notice/assets/notice.js"; //公告通知
import { study, ExamResult } from "@p/study/assets/study.js"; //学习
import { message } from "@p/message/assets/message.js"; //课程通知
import { user } from "@p/user/assets/user.js"; //用户中心
import { search } from "@p/search/assets/search.js"; //搜题
import { questionnaire } from "@p/questionnaire/assets/questionnaire.js"; //问卷作答
import { video } from "@p/video/assets/video.js"; //视频
import { learn } from "@p/learn/assets/learn.js"; //课程
import { tiku_search } from "@p/tiku_search/assets/tiku_search.js"; //搜题
import { homework } from "@p/homework/assets/homework.js"; //作业作答、排行榜
import { tiku } from "@p/pratice/tiku/assets/tiku/"; //题库首页
import { pape_do } from "@p/pratice/pape_do/assets/pape_do";//题库作答过程
import { praticeType } from '@p/pratice/praticeType/assets/praticeType.js'//题库类型
import { paper_report } from "@p/pratice/paper_report/assets/paper_report";//题库作答过程
import { newlearn } from "@p/newlearn/assets/newlearn";//新学习
import { examRequire } from "@p/examRequire/assets/examRequire"; //新考试
import { xiaomi } from "@p/xiaomi/assets/xiaomi"; //新考试
import { examTicket } from "@p/xiaomi/MyExam/assets/MyExam"; //新考试
import { pay } from "@p/pay/assets/pay.js"

import { getname,toKey,schoolextinfo } from '../api/api.js';
Vue.use(VueRouter);
import { getCookie } from '@/assets/js/cookie.js';
import { setCookie } from "../assets/js/cookie.js";
const routes = [
    //有头部公用组件路由
    {
        path: "/",
        component: (resolve) =>
            require(["@/views/components/haveHeader/haveHeader.vue"], resolve),
        children: [
            ...home,
            ...notice,
            ...study,
            ...message,
            ...user,
            ...search,
            ...questionnaire,
            ...learn,
            ...tiku_search,
            ...homework,
            ...tiku,
            ...praticeType,
            ...paper_report,
            ...xiaomi,
            ...pay,
        ],
    },
    //无头部路由
    ...newlearn,
    ...examRequire,
    ...video,
    ...pape_do,
    ...ExamResult,
    ...examTicket,
    {
        path: '*',
        redirect: '/#/'
    }
];
//重复点击导航路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
    mode: "hash",
    base: '/student/',
    routes,
});
let titleNmae = ''
let firstName = ''
toKey().then()
getname().then(res => {
    if (res.code == 0) {
        if(res.data.school_id!=0){
            setCookie('siteid', res.data.school_id)
            //北大学堂时颜色变化
            if (res.data.school_id == '55' || res.data.school_id == '46'|| res.data.school_id == '60'|| res.data.school_id == '61'|| res.data.school_id == '62'|| res.data.school_id == '63'|| res.data.school_id == '64'|| res.data.school_id == '65'|| res.data.school_id == '66'|| res.data.school_id == '68'|| res.data.school_id == '69') {
                document.documentElement.style.setProperty(
                    '--change-color',
                    '#95060B'
                );
                document.documentElement.style.setProperty(
                    '--change-hovercolor',
                    '#770509'
                );
                document.documentElement.style.setProperty(
                    '--change-plain-color',
                    '#F9ECED'
                );
                document.documentElement.style.setProperty(
                    '--change-tagline-color',
                    '#ECC8CB'
                );
                document.documentElement.style.setProperty(
                    '--change-darkcolor',
                    '#c1d1fe'
                );
                document.documentElement.style.setProperty(
                    '--change-bgcolor',
                    '#CC4247'
                );
            } else {
                document.documentElement.style.setProperty(
                    "--change-color",
                    "#507fff"
                );
                document.documentElement.style.setProperty(
                    "--change-hovercolor",
                    "#4b78f0"
                );
                document.documentElement.style.setProperty(
                    "--change-plain-color",
                    "#f1f5fe"
                );
                document.documentElement.style.setProperty(
                    "--change-tagline-color",
                    "#cae4ff"
                );
            }
        }
        // setCookie('siteid', 55)
        sessionStorage.setItem('tourist_switch', res.data.tourist_switch)
        sessionStorage.setItem('info_name', JSON.stringify(res.data))
    }
})
if(getCookie('siteid')!=0 && getCookie('siteid')!=null){
    schoolextinfo({}).then(res => {
        if (res.code == 0) {
            sessionStorage.setItem('schoolInfo', JSON.stringify(res.data))
            titleNmae = res.data.name
            document.title = firstName ? firstName + '-' + titleNmae : titleNmae
        }
    })
}
router.beforeEach((to, from, next) => {
    firstName = to.meta.title ? to.meta.title : ''
    document.title = to.meta.title ? to.meta.title + '-' + titleNmae : titleNmae
    if (to.path == '/home' || to.path == '/search' || to.path == '/learn') {
        next()
    }else {
        if (getCookie('siteid') == null) {
            if (window.location.href.indexOf('localhost') > -1) {
                next("http://localhost:8080/login/#/")
            } else {
                // let hostname = window.location.hostname;
                // next('//' + hostname + '/login/#/')
                const hostname = window.location.hostname
                let formdata = sessionStorage.getItem('info_name') != null ? JSON.parse(sessionStorage.getItem('info_name')) : ''
                if (formdata != '' && formdata.hasOwnProperty('scheme') && formdata.scheme != '') {
                    let agreement = formdata.scheme
                    next(agreement + '://' + hostname + '/login/#/')
                } else {
                    next('//' + hostname + '/login/#/')
                }
            }
        }
        next()
    }
})

export default router;
