<template>
	<div class="headerWrap">
		<div class="headerBox">
			<div class="headerLeft">
				<a href="javascript:void(0)"
					><img
						:src="
							logoUrl
								? '//' + logoUrl
								: require('./assets/images/xheaderLogo.png')
						"
						alt=""
				/></a>
				<ul>
					<li
						:class="nowActive == item.id ? 'active' : ''"
						v-for="(item, index) in tabList"
						:key="index"
						@click="changeTab(item)"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
			<div class="headerRight">
				<!-- 登录前 -->
				<template v-if="!loginFlag">
					<el-button round class="newBtn" @click="login"
						>登录</el-button
					>
					<el-button round class="newBtn" @click="login"
						>注册</el-button
					>
				</template>
				<!-- 登录后 -->
				<template v-else>
					<span class="fontSize14 mr15">{{ name }}</span>
					<el-button type="text" @click.native="jumpUser()"
						>退出</el-button
					>
				</template>
				<!--  <template v-else>
          <ul>
            <li
              :class="nowActive == item.id ? 'active' : ''"
              v-for="(item, index) in righttabList"
              :key="index"
              @click="changeTab(item)"
            >
              {{ item.name }}
            </li>
          </ul>
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-avatar
                :src="
                  img
                    ? img
                    : require('@/assets/images/head_default.png')
                "
                icon="el-icon-user-solid"
              ></el-avatar>
              <i class="iconfont icon-xl ml5"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in userList"
                :key="index"
                @click.native="jumpUser(item)"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>-->
			</div>
		</div>
	</div>
</template>

<script>
import { logout } from '@/views/components/assets/api.js';
import { delCookie } from '@/assets/js/cookie';
export default {
	watch: {
		$route: {
			handler: function (val, o) {
				console.log(val, o);
				this.nowActive = val.meta.type
					? val.meta.type
					: val.query.metaType;
			},
			deep: true,
		},
	},
	data() {
		return {
			school_name: '',
			tabList: [
				// {
				// 	id: 11,
				// 	name: '首页',
				// 	path: '/xhome',
				// },
				// {
				// 	id: 12,
				// 	name: '认证报名',
				// 	path: '/xsign',
				// },
				// {
				// 	id: 13,
				// 	name: '高校合作',
				// 	path: '/xcoop',
				// },
				// {
				// 	id: 14,
				// 	name: '企业培训',
				// 	path: '/xtrain',
				// },
				// {
				// 	id: 15,
				// 	name: '备考学习',
				// 	path: '/xstudy',
				// },
			],
			righttabList: [
				// {
				// 	id: 2,
				// 	name: '我的考试',
				// 	path: '/MyExam',
				// },
			],
			nowActive: 11,
			img: '',
			userList: [
				// {
				// 	id: 1,
				// 	name: '个人信息',
				// 	path: '/archives_student',
				// },
				// {
				// 	id: 4,
				// 	name: '反馈建议',
				// 	path: '/feedback',
				// },
				// {
				// 	id: 5,
				// 	name: '账号设置',
				// 	path: '/account',
				// },
				{
					id: 6,
					name: '退出登录',
				},
			],
			loginFlag: false,
			logoUrl: '',
			name: '',
		};
	},
	mounted() {
		if (sessionStorage.getItem('schoolInfo')) {
			this.logoUrl = JSON.parse(
				sessionStorage.getItem('schoolInfo')
			).logo;
		}
		this.nowActive = this.$route.meta.type;
		if (sessionStorage.getItem('infos')) {
			this.loginFlag = true;
			this.img = JSON.parse(sessionStorage.getItem('infos')).head_img;
			this.name = JSON.parse(sessionStorage.getItem('infos')).name;
			this.school_name = JSON.parse(
				sessionStorage.getItem('infos')
			).school_name;
		}
		window.addEventListener('setItem', (event) => {
			if (event.key === 'infos') {
				this.img = JSON.parse(event.newValue).head_img;
				this.school_name = JSON.parse(event.newValue).school_name;
				this.name = JSON.parse(event.newValue).name;
				this.loginFlag = true;
			}
			if (event.key === 'schoolInfo') {
				this.logoUrl = JSON.parse(event.newValue).logo;
			}
		});
	},
	methods: {
		login() {
			let hostname = window.location.hostname;
			window.location.href = '//' + hostname + '/login/#/';
		},
		changeTab(item) {
			this.nowActive = item.id;
			this.$router.push(item.path);
		},

		jumpUser() {
			//退出登录
			logout().then((res) => {
				if (res.code == 0) {
					if (sessionStorage.getItem('hosttype')) {
						sessionStorage.removeItem('infos');
						sessionStorage.removeItem('course');
						sessionStorage.removeItem('str_column');
						sessionStorage.removeItem('column_id');
						sessionStorage.removeItem('hosttype');
						delCookie('siteid');
						delCookie('infos');
						delCookie('faculty_id');
						if (window.location.href.indexOf('localhost') > -1) {
							window.location.href =
								'http://localhost:8080/exam/#/?hosttype=pcclient';
						} else {
							let hostname = window.location.hostname;
							window.location.href =
								'//' +
								hostname +
								'/exam/#/?hosttype=pcclient';
						}
					} else {
						sessionStorage.removeItem('infos');
						sessionStorage.removeItem('course');
						sessionStorage.removeItem('str_column');
						sessionStorage.removeItem('column_id');
						delCookie('siteid');
						delCookie('infos');
						delCookie('faculty_id');
						if (window.location.href.indexOf('localhost') > -1) {
							window.location.href =
								'http://localhost:8080/exam/#/';
						} else {
							let hostname = window.location.hostname;
							window.location.href =
								'//' + hostname + '/exam/#/';
						}
					}
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import './assets/xheader.scss';
</style>
