<template>
    <div style="height: 100%">
        <Header :bgColor="bgColor"></Header>
        <div v-if="Object.keys(course).length != 0" class="courseinfoWrap">
            <div class="course-info-wrapper" style="height: 100%" v-if="course.disabled == 0 && course.is_expired == 0 ">
                <div class="leftWrap">
                    <div class="courseinfoTitle">
                        <div class="imgWrap">
                            <img v-if="course.course_image" :src="course.course_image" />
                            <img v-else :src="require('../../../assets/images/nocourse.png')" />
                            <span class="godoor" @click="godoor">去门户</span>
                        </div>
                        <div class="titleWrap">
                            {{ course.course_title }}
                        </div>
                    </div>
                    <div class="course-info-menu">
                        <template v-for="item of pageMenus">
                            <a :key="item.name" :class="{ 'active-page-menu': item.id == activeMenuPath }"
                                @click="save(item.id)" v-if="item.id != 8 && item.value">
                                <i :class="['iconfont', item.icon]" />
                                <span>{{ item.name
                                    }}<em v-if="item.id == 6 && num" class="marks">{{
                                        num > 99 ? "99+" : num
                                    }}</em></span>
                            </a>
                            <!-- v-if="item.id==8&&is_settings==1" -->
                            <a :key="item.name" :class="{ 'active-page-menu': item.id == activeMenuPath }"
                                @click="save(item.id)" v-if="item.id == 8 && is_settings == 1 && item.value">
                                <i :class="['iconfont', item.icon]" />
                                <span>{{ item.name
                                    }}<em v-if="item.id == 6 && num" class="marks">{{
                                        num > 99 ? "99+" : num
                                    }}</em></span>
                            </a>
                        </template>
                        <div class="add_new" v-if="(course.in_list == 1 && course_claim_info.learning_authority==3) || course.certificate_id > 0">
                            <a v-if="course.in_list == 1 && course_claim_info.learning_authority==3" @click="upgradeCertification">
                                <img src="./assets/images/icon_pattern.png" alt=""> 升级认证模式
                            </a>
                            <span v-if="course.in_list == 1 && course.certificate_id > 0  && course_claim_info.certificate_id>0"></span>
                            <a v-if="course.certificate_id > 0 "
                                @click="isState($route.query.course_id, course.certificate_id)">
                                <img src="./assets/images/icon_certificate.png" alt=""> 证书
                            </a>
                        </div>
                    </div>
                </div>
                <div class="rightWrap">
                    <courseAct v-if="activeMenuPath == 0"></courseAct>
                    <courseNotice v-if="activeMenuPath == 6"></courseNotice>
                    <courseHomework v-if="activeMenuPath == 7"></courseHomework>
                    <CourseAchievement v-if="activeMenuPath == 8"></CourseAchievement>
                    <courseExam v-if="activeMenuPath == 9"></courseExam>
                    <div class="actbg" v-if="activeMenuPath == 3">
                        <CourseData ref="CourseData" :ssid="course.sou_school_id"></CourseData>
                    </div>
                    <div class="actbg" v-if="activeMenuPath == 4">
                        <CourseNote ref="CourseNote"></CourseNote>
                    </div>
                    <div class="actbg" v-if="activeMenuPath == 5">
                        <CourseEval ref="CourseEval" :is_expired="course.is_expired"
                            :appraisal_star="course.appraisal_star">
                        </CourseEval>
                    </div>
                    <div class="actbg" v-if="activeMenuPath == 2">
                        <CoursePlan ref="CoursePlan" v-if="activeMenuPath == 2" :modelFlag="course.is_stage"
                            :expired="course.is_expired"></CoursePlan>
                    </div>
                </div>
            </div>
            <nolearn ref="nolearn" v-else :course_id="course.course_id"></nolearn>
        </div>
        <authentication ref="authentication" @superiorLearn="learnEvent"></authentication>
        <certificationUpgrade ref="certificationUpgrade" @goPlaceAnOrder="goPlaceAnOrder"></certificationUpgrade>
        <!-- 证书状态 -->
        <CertificateStatus ref="CertificateStatus" @goUpgrade="goPlaceAnOrder" @goBuy="snbuyNow"></CertificateStatus>
        <applyState ref="applyState" @tipsShow="tipsShow"></applyState>
        <informationPrompt ref="informationPrompt" @determine="determine"></informationPrompt>
    </div>
</template>

<script>
import courseExam from "./components/courseExam";
import CourseAchievement from "./components/courseAchievement.vue";
import Header from "@p/video/components/header";
import courseAct from "./components/courseAct";
import courseNotice from "./components/courseNotice";
import courseHomework from "./components/courseHomework";
import CourseData from "@p/learn/components/courseData.vue";
import CourseNote from "@p/learn/components/courseNote.vue";
import CourseEval from "@p/learn/components/courseEval.vue";
import CoursePlan from "@p/newlearn/components/coursePlan.vue";
import nolearn from "./components/nolearn";
import { courseInfo, certificateStatus, webInfo } from "../learn/assets/api";
import { notifilistnum, courseLearn } from "./assets/api";
import authentication from "@p/learn/components/authentication.vue";
import certificationUpgrade from "@p/learn/components/certificationUpgrade.vue";
import CertificateStatus from "@p/learn/components/certificate_status.vue";
import applyState from "@p/learn/components/applyState.vue";
import informationPrompt from "@p/learn/components/informationPrompt.vue";
export default {
    components: {
        nolearn,
        Header,
        courseAct,
        courseNotice,
        courseHomework,
        courseExam,
        CourseData,
        CourseNote,
        CourseEval,
        CoursePlan,
        CourseAchievement,
        CertificateStatus,
        applyState,
        informationPrompt,
        authentication,
        certificationUpgrade
    },
    data () {
        return {
            bgColor: true,
            activeMenuPath: 0,
            pageMenus: [
                {
                    id: 2,
                    name: "课件安排",
                    icon: "icon-kjap",
                    path: 'lesson'
                },
                {
                    icon: "icon-uxbjxhd",
                    name: "教学活动",
                    id: 0,
                    path: 'activity'
                },
                {
                    icon: "icon-icon_wzzjk",
                    name: "通知",
                    id: 6,
                    path: 'notification'
                },
                {
                    icon: "icon-zuoye",
                    name: "作业",
                    id: 7,
                    path: 'assignment'
                },
                {
                    icon: "icon-kcbj",
                    name: "考试",
                    id: 9,
                    path: 'examination'
                },
                // {
                //   id: 1,
                //   name: "课程介绍",
                //   icon: "icon-kcjs",
                // },

                {
                    id: 3,
                    name: "课后资料",
                    icon: "icon-khzl",
                    path: 'after_school'
                },
                {
                    id: 4,
                    name: "课程笔记",
                    icon: "icon-kcbj",
                    path: 'note'
                },
                {
                    id: 5,
                    name: "课程评价",
                    icon: "icon-kcpj1",
                    path: 'appraise'
                },
                {
                    id: 8,
                    name: "成绩管理",
                    icon: "icon-kcpj1",
                    path: 'performance'
                },
            ],
            num: 0,
            course: {},
            is_settings: "",
            course_claim_info: {}
        };
    },
    // beforeRouteEnter(to,from,next) {
    //   if(from.path=='/examRequire'){
    //
    //     next(vm=>{
    //       vm.activeMenuPath = 9
    //     })
    //   }
    //   next()
    // },
    watch: {
        $route: {
            handler: function () {
                this.getCourseLearn();
            },
            deep: true,
        },
    },
    mounted () {
        if (sessionStorage.getItem('activeMenuPath')) {
            this.activeMenuPath = sessionStorage.getItem('activeMenuPath')
        }
        //   this.getcourseInfo();
        this.getCourseLearn();
    },
    methods: {
        // 升级认证模式
        upgradeCertification () {
            this.$refs.certificationUpgrade.openInit(this.course_claim_info.price)
        },
        getCourseLearn () {
            let params = {
                course_id: this.$route.query.course_id
            }
            courseLearn(params).then(res => {
                if (res.code == 0) {
                    if (res.data.in_list == 0 || res.data.class_id == 0 || res.data.is_expired == 1) {
                        this.$router.push({
                            path: "/learn",
                            query: { course_id: this.$route.query.course_id },
                        });
                        return
                    }
                    this.course = res.data;
                    Object.keys(res.data.settings).forEach(key => {
                        this.pageMenus.forEach(item => {
                            if (((item.path && item.path == key) && res.data.settings[key] == 1) || !item.path) {
                                this.$set(item, 'value', 1)
                            } else if ((item.path && item.path == key) && res.data.settings[key] == 0) {
                                this.$set(item, 'value', 0)
                            }
                        })
                    })
                    try {
                        this.pageMenus.map(item => {
                            if (item.value) {
                                this.activeMenuPath = item.id
                                throw new Error()
                            }
                        })
                    } catch (e) {

                    }
                    this.$nextTick(() => {
                        this.$addStorageEvent(2, "course", JSON.stringify(res.data));
                        this.getnotNum();
                        if (res.data.in_list == 0 || res.data.is_expired != 0) {
                            this.$refs.nolearn.times();
                        }
                    });
                } else {
                    this.$router.push({
                        path: "/learn",
                        query: { course_id: this.$route.query.course_id },
                    });
                }
            })
            webInfo(params).then(res => {
                if (res.code == 0) {
                    this.course_claim_info = res.data.course_stable.course_claim_info
                }
            })
        },
        // 消息提示框
        tipsShow (e) {
            this.$refs.informationPrompt.openInit(e)
        },
        // 消息框确定
        determine (e) {
            this.$refs.applyState.doSubmit(e)
        },
        // 判断证书状态
        isState (course_id, certificate_id) {
            let params = {
                course_id,
                certificate_id
            }
            certificateStatus(params).then(res => {
                if (res.code == 0) {
                    let status = res.data.cert_test.status
                    let certificate_type = res.data.cert_test.certificate_type
                    if (certificate_type == 2) {
                        this.$refs.CertificateStatus.openInit(certificate_type)
                    }else{
                        
                        if (status == 10 || status == 9 || status == 8 || status == 7) {
                            this.$refs.CertificateStatus.openInit(certificate_type,status)
                        } else if (status == 5 || status == 6) {
                            this.$refs.CertificateStatus.openInit(certificate_type,status, res.data.cert_test.copywriting)
                        } else {
                            this.$refs.applyState.openInit(res.data.cert_test, this.course.course_title)
                        }
                    }

                }
            })
        },
        learnEvent () {
            this.learns(this.$route.query.course_id, this.course_basics.default_class_id)
        },
        learns (id, cst) {
            let params = {
                course_id: id,
                teach_class_id: cst
            }
            addclass(params).then(res => {
                if (res.code == 0) {
                    this.$router.push({
                        path: "/newlearn",
                        query: {
                            course_id: id,
                            cst_id: cst,
                        },
                    });
                }
            })

        },
        // 去下单
        goPlaceAnOrder () {
            this.$router.push({
                path: '/pay',
                query: {
                    course_id: this.$route.query.course_id,
                    claim_id: this.course_claim_info.claim_id
                }
            })
        },
        // 立即购买
        snbuyNow () {
            if (this.course_claim_info.param_project_no == '') {
                this.$refs.playRemind.openInit()
            } else {
                this.goPlaceAnOrder()
            }
        },
        save (val) {
            this.activeMenuPath = val
            sessionStorage.setItem('activeMenuPath', val)
        },
        godoor () {
            this.$router.push({
                path: "/learn",
                query: { course_id: this.$route.query.course_id },
            });
        },
        getcourseInfo () {
            let params = {
                course_id: this.$route.query.course_id,
            };
            courseInfo(params).then((res) => {
                if (res.code == 0) {
                    this.course = res.data;
                    Object.keys(res.data.settings).forEach(key => {
                        this.pageMenus.forEach(item => {
                            if (((item.path && item.path == key) && res.data.settings[key] == 1) || !item.path) {
                                this.$set(item, 'value', 1)
                            } else if ((item.path && item.path == key) && res.data.settings[key] == 0) {
                                this.$set(item, 'value', 0)
                            }
                        })
                    })
                    try {
                        this.pageMenus.map(item => {
                            if (item.value) {
                                this.activeMenuPath = item.id
                                throw new Error()
                            }
                        })
                    } catch (e) {

                    }
                    this.$nextTick(() => {
                        this.$addStorageEvent(2, "course", JSON.stringify(res.data));
                        this.getnotNum();
                        if (res.data.in_list == 0 || res.data.is_expired != 0) {
                            this.$refs.nolearn.times();
                        }
                    });
                } else if (res.message == "课程不存在。") {
                    // this.$refs.nolearn.times();
                    console.log(1111111111111);
                }
            });
        },
        getnotNum () {
            let params = {
                teach_class_id: this.$route.query.cst_id,
            };
            notifilistnum(params).then((res) => {
                if (res.code == 0) {
                    this.num = res.data.num;
                }
            });
        },
    },
    destroyed () {
        // sessionStorage.removeItem('activeMenuPath')
    },
    beforeRouteLeave (to, from, next) {
        if (to.path == '/examRequire' || to.path == '/examReport') {

        } else {
            sessionStorage.removeItem('activeMenuPath')
        }
        next()
    }
};
</script>
<style scoped lang="scss">
.actbg {
    background: #fff;
    border-radius: 3px;
    height: 100%;
    box-sizing: border-box;
}

.courseinfoWrap {
    padding-top: 73px;
    box-sizing: border-box;
    height: 100%;
}

.courseinfo-page {
    display: flex;
    margin-top: 19px;
    height: 301px;

    .img-wrapper {
        width: 465px;
        height: 261px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }

    .info-wrapper {
        margin: 12px 0 0 55px;

        .title {
            font-size: 26px;
            color: #333333;
        }

        .valid-time {
            height: 21px;
            margin-top: 15px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 21px;
            letter-spacing: 0;
            color: #888888;
        }

        .tags {
            height: 21px;

            .tag-item {
                float: left;
                text-align: left;
                font-size: 16px;
                color: #666;
                margin-right: 30px;

                .iconfont {
                    color: #666;
                }
            }
        }

        .btn-edit {
            margin-top: 28px;

            .el-button {
                width: 120px;
                height: 44px;
                font-size: 16px;
            }
        }
    }
}

.marks {
    background: #FFB546;
    color: #fff;
    width: 32px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    border-radius: 12px;
    padding: 0 4px;
    margin-left: 10px;
    box-sizing: border-box;
    font-size: 12px;
}

.course-info-wrapper {
    display: flex;

    .leftWrap {
        background-color: #fff;
        position: fixed;
        width: 200px;
        height: calc(100% - 50px);
        background: #fff;

        .courseinfoTitle {
            padding: 20px;
            font-size: 14px;
            line-height: 20px;
            border-bottom: 1px solid #ebeef5;

            .imgWrap {
                position: relative;
                border-radius: 4px;

                img {
                    display: block;
                    width: 160px;
                    height: 90px;
                    border-radius: 4px;
                }

                .godoor {
                    cursor: pointer;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 31px;
                    text-align: center;
                    line-height: 31px;
                    background: rgba(0, 0, 0, 0.4);
                    color: #fff;
                    font-size: 14px;
                    border-radius: 0 0 4px 4px;
                }

                .imgBottom {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 31px;
                    text-align: center;
                    line-height: 31px;
                    background: rgba(0, 0, 0, 0.5);
                    color: #fff;
                    font-size: 14px;
                    border-radius: 0 0 4px 4px;
                }
            }

            .titleWrap {
                margin-top: 10px;
                width: 100%;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .course-info-menu {
        padding: 20px;
        left: 0;
        border: none;
        background-color: #fff;

        .add_new {
            // height:90px;
            background: linear-gradient(to top, #FEF2E3, #FEE8D4);
            border: 1px solid #F5E0C8;
            padding: 8px 10px;
            box-sizing: border-box;
            border-radius: 4px;

            a {
                height: 34px;
                width: auto;
                line-height: 34px;
                padding: 0;
                color: #743C0B;
                font-size: 14px;
                margin: 0;
                border-radius: 4px;

                img {
                    width: 18px;
                    height: 18px;
                    margin: 8px 10px 0 10px;
                }
            }

            a:hover {
                background: #F9E1C2;
            }

            span {
                display: block;
                border-top: 1px solid #FBE5CA;
                height: 3px;
                margin-top: 3px;
            }
        }

        a {
            width: 160px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            display: flex;
            margin-bottom: 10px;
            padding: 0 20px;
            box-sizing: border-box;
            cursor: pointer;

            i {
                font-size: 18px;
                color: #a6a6c1;
            }

            span {
                margin-left: 10px;
            }
        }

        a:hover {
            background-color: #f8f8f8;
            color: #333;
        }

        a.active-page-menu {
            border-radius: 4px;
            background-color: var(--change-color);
            color: #fff;

            i {
                color: #fff;
            }
        }

        .courseinfoTitle {
            height: 195px;
        }
    }

    .rightWrap {
        flex: 1;
        min-height: 830px;
        padding: 20px 20px 0 220px;
        border-left: 1px solid #eef1f3;
        box-sizing: border-box;
    }

    .page-content {
        border-radius: 3px;
        background-color: #fff;
    }

    .uxbfanhui1 {
        color: #787d84;
    }
}
</style>
