<template>
    <div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="400px" :before-close="handleClose" custom-class="stateClass" :close-on-click-modal="false">
            <span>确认信息无误？<br>提交后，无法修改哦~</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" type="info">取 消</el-button>
                <el-button type="primary" @click="determine(status)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    data(){
        return{
            dialogVisible:false,
            status:1
        }
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
        },
        openInit(status){
            this.dialogVisible = true
            this.status = status
        },
        determine(status){
            this.handleClose()
            this.$emit('determine',status)
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .stateClass {
    border-radius: 20px;
    background: #F8F8F8;
    .el-dialog__body{
        text-align: center;
    }
    .el-dialog__footer{
        text-align: center;
    }
}
</style>