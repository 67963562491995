// 首页路由
export const home = [
    {
        path: '/home',
        name: 'home',
        component: resolve => require(['@p/home/home.vue'], resolve),
        meta: {
            type: 1,
            title: '首页'
        }
    },
];
