<template>
	<div class="taskWrap">
		<div class="titleWrap">
			<div>
				<ul class="taskTab">
					<li
						v-for="(item, index) in taskTab"
						:key="index"
						:class="nowActive == item.id ? 'on' : ''"
						@click="changeTab(item)"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
		</div>
		<div class="noDataWrap" v-if="taskList.length > 0">
			<img src="@/assets/images/noData.png" alt="" />
			<p v-if="nowActive == 0">暂无已获得证书</p>
			<p v-if="nowActive == 1">暂无待申请证书</p>
			<p v-if="nowActive == 2">暂无已驳回证书</p>
            <p v-if="nowActive == 3">暂无审核中证书</p>
		</div>
		<div v-else>
			<div class="certificateWrap">
                <dl>
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span @click="ViewCertificate">查看证书</span>
                    </dd>
                </dl>
                <dl>
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span @click="certificateState">查看证书</span>
                    </dd>
                </dl>
                <dl>
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span>查看证书</span>
                    </dd>
                </dl>
                <dl class="noMargin">
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span>查看证书</span>
                    </dd>
                </dl>
                <dl>
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span>查看证书</span>
                    </dd>
                </dl>
                <dl>
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span>查看证书</span>
                    </dd>
                </dl>
                <dl>
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span>查看证书</span>
                    </dd>
                </dl>
                <dl class="noMargin">
                    <dt>
                        <img src="@/assets/images/1.jpg" alt="">
                    </dt>
                    <dd>
                        <p>2020度最具发展潜力荣誉证书荣誉证书荣誉证书</p>
                        <span>查看证书</span>
                    </dd>
                </dl>
            </div>
            <div class="order_pages">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper" :total="400">
                </el-pagination>
            </div>
		</div>
        <ViewCertificate ref="ViewCertificate"></ViewCertificate>
        <ApplyState ref="ApplyState"></ApplyState>
	</div>
</template>

<script>
import { taskList } from '../assets/api';
import ViewCertificate from './com/view_certificate.vue';
import ApplyState from './com/apply_state.vue';
export default {
	components: {
        ViewCertificate,
        ApplyState
	},
	data() {
		return {
			taskList: [],
			page: 1,
			limit: 20,
			btnMessage: '加载更多',
			taskTab: [
				{
					id: 0,
					name: '已获得',
				},
				{
					id: 1,
					name: '待申请',
				},
				{
					id: 2,
					name: '已驳回',
				},
                {
					id: 3,
					name: '审核中',
				},
			],
			nowActive: 0,
            currentPage: 4,
		};
	},
	mounted() {
		this.getList(1);
	},
	methods: {
        certificateState(){
            this.$refs.ApplyState.openInit()
        },
        ViewCertificate(){
            this.$refs.ViewCertificate.openInit()
        },
		getList(type) {
			if (type == 1) {
				this.page = 1;
				this.taskList = [];
			}
			const params = {
				uid: JSON.parse(sessionStorage.getItem('infos')).uid,
				limit: this.limit,
				page: this.page,
				status: this.nowActive,
			};
			taskList(params).then((res) => {
				if (res.code == 0) {
					if (res.data) {
						if (type == 1) {
							this.taskList = res.data;
						}
						if (type == 2) {
							this.taskList = this.taskList.concat(res.data);
						}
					} else {
						this.btnMessage = '暂无更多';
					}
				} else {
					this.page--;
				}
			});
		},
		changeTab(item) {
			this.nowActive = item.id;
			this.taskList = [];
			this.limit = 20;
			this.page = 1;
			this.getList(1);
		},
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`);
        }
	},
};
</script>

<style scoped lang="scss">
.certificateWrap{
    margin:32px 0 0 0;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    dl{
        width:252px;
        height:318px;
        background-color:#fff;
        margin:0 23px 24px 0;
        dt{
            width:252px;
            height:190px;
            background-color:#EEEEEE;
            border-radius:6px 6px 0 0;
            display: flex;
            align-items: center;
            img{
                width:100%;
            }
        }
        dd{
            width:252px;
            height:128px;
            background-color:#fff;
            box-shadow: 0 0 5px 2px #eee;
            border-radius: 0 0 6px 6px;
            text-align: center;
            p{
                font-size: 16px;
                line-height: 24px;
                color:#333;
                text-align: left;
                padding:12px 20px 8px 20px;
            }
            span{
                display: inline-block;
                width:108px;
                height:32px;
                border:1px solid var(--change-color);
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                color:var(--change-color);
                cursor: pointer;
                &:hover{
                    color:#fff;
                    background:var(--change-color);
                }
            }
        }
    }
    .noMargin{
        margin: 0 0 24px 0;
    }
}
.order_pages {
        display: flex;
        justify-content: end;
    }
.taskWrap {
	padding: 20px 30px;
	box-sizing: border-box;
	.noDataWrap {
		text-align: center;
		margin: 100px 113px;
		p {
			margin: 25px 0;
			font-size: 16px;
			color: #999999;
		}
	}
	.titleWrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.taskTab {
			display: flex;
			li {
				margin-right: 40px;
				font-size: 16px;
				cursor: pointer;
				position: relative;
				&.on {
					color: var(--change-color);
					font-weight: bold;
				}
				&.on::after {
					clear: both;
					content: '';
					position: absolute;
					left: 50%;
					bottom: -9px;
					width: 24px;
					height: 3px;
					margin-left: -12px;
					background: var(--change-color);
					border-radius: 1.5px;
				}
			}
		}

		i {
			font-size: 16px;
		}
		.el-button.is-round {
			//padding: 10px 20px;
      width: 118px;
      height: 36px;
      padding:10px 23px
		}
    .bluePlainBtn{
      background:#fff;
      border:1px solid var(--change-color);
      color:var(--change-color);
      i{}
      &:hover{
        background:var(--change-color);
        color:#fff
      }
    }
	}

}
.moreBtn {
	margin: 0 auto;
	margin-top: 30px;
	width: 100px;
	height: 30px;
	font-size: 14px;
	border-radius: 18px;
	border: 1px solid #e1e1e1;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	color: #666;
	&:hover {
		background: var(--change-color);
		border: 1px solid var(--change-color);
		color: #fff;
	}
	i {
		font-size: 14px;
	}
}
</style>