<template>
    <div class="courseWrapparent">
        <div class="courseWrap">
            <div class="main">
                <div class="courseWrapmbx">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item> 搜课</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="courseWrapdiv">
                    <!-- <div class="courseWrapdivtype clearfix">
            <h6 class="fontSize14 lineH26">课程类型：</h6>
            <ul class="courseWrapdivtypeoul clearfix">
              <li
                :class="courseindex == item.id ? 'on' : ''"
                v-for="(item, index) in list.coursename"
                :key="index"
                @click="courseClick(item)"
              >
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </div> -->
                    <!--<div class="courseWrapdivtype clearfix">
            <h6 class="fontSize14 lineH26">院系：</h6>
            <div class="courseWrapdivtypeoul">
              <ul class="clearfix oullist" :class="isZh[0] ? 'on' : ''">
                <li
                  :class="yxindex == item.id ? 'on' : ''"
                  v-for="(item, index) in list.facultyinfos"
                  :key="index"
                  @click="yxClick(item)"
                >
                  <span>{{ item.name }}</span>
                </li>
              </ul>
              <p
                class="courseWrapdivtypemore"
                @click="clickfun(0)"
                :class="isZh[0] ? 'on' : ''"
              >
                {{ isZh[0] ? "收起" : "展开"
                }}<i class="iconfont icon-lesson_detail_down"></i>
              </p>
            </div>
          </div>
          <div class="courseWrapdivtype clearfix" v-show="yxindex!=0">
            <h6 class="fontSize14 lineH26">专业/专业大类：</h6>
            <div class="courseWrapdivtypeoul">
              <ul class="clearfix oullist" :class="isZh[1] ? 'on' : ''">
                <li
                  v-for="(item,index) in firstmajorlist"
                  :key="index"
                  class="courseWrapdivtypeouloli1"
                  :class="{on:classflag==item.id}"
                  @click="getmajorinfo(item)"
                  @mouseleave="leaveflag(item)"
                >
                  <span
                    >{{item.name}}<i class="iconfont icon-lesson_detail_down" v-if="item.haschild==1"></i
                    ><em v-if="item.haschild==1"> <img src="./assets/images/offcn_yxbbg60.png" /> </em
                  ></span>
                  <i style="display: block; height: 18px; margin-top: -2px" v-if="item.haschild==1&&item.show"></i>
                  <div class="courseWrapdivtypeould" v-if="item.haschild==1&&item.show">
                    <p v-for="(it,ins) in twomajorlist" :key="ins" @click.stop="twomase(it)">{{it.name}}</p>
                  </div>
                </li>
              </ul>
              <p
                class="courseWrapdivtypemore"
                @click="clickfun(1)"
                :class="isZh[1] ? 'on' : ''"
              >
                {{ isZh[1] ? "收起" : "展开"
                }}<i class="iconfont icon-lesson_detail_down"></i>
              </p>
            </div>
          </div>-->
                    <div class="courseWrapdivtype clearfix">
                        <h6 class="fontSize14 lineH26">学科：</h6>
                        <div class="courseWrapdivtypedivfr">
                            <div class="courseWrapdivtypeoul">
                                <ul class="clearfix oullist" :class="isZh[2] ? 'on' : ''">
                                    <li :class="xkindex == item.menu_id ? 'on' : ''"
                                        v-for="(item, index) in list.subject_tree" :key="index" @click="xkClick(item)">
                                        <span>{{ item.name }}
                                            <i class="iconfont icon-lesson_detail_down"
                                                v-if="item.child.length > 0"></i><em v-if="item.child.length > 0">
                                                <img src="./assets/images/offcn_yxbbg60.png" /> </em></span>
                                    </li>
                                </ul>
                                <p class="courseWrapdivtypemore" @click="clickfun(2)" :class="isZh[2] ? 'on' : ''">
                                    {{ isZh[2] ? "收起" : "展开"
                                    }}<i class="iconfont icon-lesson_detail_down"></i>
                                </p>
                            </div>
                            <div class="courseWrapdivtypeouldchild clearfix" v-if="showflag">
                                <div>{{ showname }}：</div>
                                <div v-for="(ite, inde) in subjectinfoList" :key="inde"
                                    class="courseWrapdivtypeouldchildd" :class="{ on: clasflag == ite.clickfun }"
                                    @click="gesinfo(ite)" @mouseleave="leavflag(ite)">
                                    <span>{{ ite.name
                                        }}<i class="iconfont icon-lesson_detail_down"
                                            v-if="ite.child.length > 0"></i><em>
                                            <img src="./assets/images/offcn_yxbbg60.png"
                                                v-if="ite.child.length > 0 && ite.show" /> </em></span>
                                    <i style="display: block; height: 18px" v-if="ite.child.length > 0 && ite.show"></i>
                                    <div class="courseWrapdivtypeould" v-if="ite.child.length > 0 && ite.show">
                                        <p v-for="(it, ind) in ite.child" :key="ind" @click.stop="thriseach(it)">
                                            {{ it.name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="courseWrapdivresulttj clearfix" ref="getheight" :class="!visibleHead ? 'on' : ''"
                        @mouseover="mouseo()" v-if="seachdata.length != 0">
                        <div class="courseWrapdivresulttjchild">
                            <div class="main">
                                <div class="courseWrapdivresulttjdiv" @click="clears()">
                                    清空所有条件
                                </div>
                                <p class="courseWrapdivresulttjp" v-for="(item, index) in seachdata" :key="index">
                                    {{ item.name }}<i @click="del(item, index)"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="courseWrapparentdiv" :class="!visibleHead ? 'on' : ''" v-show="visibleShow">
            <div class="courseWrapparentdivd main clearfix">
                <p v-for="(item, index) in courseTypelist" :key="index" @click="courseTypelistClick(item)"
                    :class="courseTypelistindex == item.id ? 'on' : ''">
                    {{ item.name }}
                </p>
                <div class="courseWrapparentdivdd" v-if="identity != 3">
                    <el-checkbox v-model="checkflag" @change="teacher">老师专属</el-checkbox>
                </div>
            </div>
        </div>
        <div class="courseWrapparentdivdi1 main" v-if="courseData.length != 0">
            <ul class="courseWrapparentdivdi1oul clearfix">
                <li v-for="(item, index) in courseData" :key="index" :class="(index + 1) % 5 == 0 ? 'on' : ''"
                    @click="learns(item.course_id)">
                    <div class="img">
                        <img v-if="item.image" :src="item.image" alt="" />
                        <img v-else :src="require('../../../assets/images/nocourse.png')" />
                    </div>
                    <div class="fixedHeight">
                    <h6>
                        <span v-if="course_card_config == '' || course_card_config.field1 == 'course_type'">{{
                            item.typename
                        }}</span>{{ item.title }}
                    </h6>
                    <p v-if="course_card_config != '' && course_card_config.field2 == 'subheading'">{{ item.subheading
                        }}</p>
                    <div v-if="course_card_config == '' || course_card_config.field2 == 'validity_type'">
                        <p v-if="item.start_time == '-28800'">
                            <i class="iconfont icon-icon_yxq"></i>长期有效
                        </p>
                        <p v-else-if="item.validity_day > 0">
                            <i class="iconfont icon-icon_yxq"></i>有效期{{ item.validity_day }}天
                        </p>
                        <p v-else>
                            <i class="iconfont icon-icon_yxq"></i>有效期
                            {{ item.start_time | formatimes("yyyy-MM-dd") }}~{{
                                item.end_time | formatimes("yyyy-MM-dd")
                            }}
                        </p>
                    </div>
                    </div>
                    <div v-if="course_card_config == '' || course_card_config.field3 == 'price'" class="priceBox">
                        <p v-if="item.learning_authority == 0" class="price">免费</p>
                        <p v-else-if="item.learning_authority == 1">暂不对公众开放</p>
                        <p v-else-if="item.learning_authority == 2" class="price">￥{{ item.price }}</p>
                        <p v-else class="price">免费</p>
                    </div>
                    <div v-if="course_card_config != '' && course_card_config.field3 == 'validity_type'">
                        <p v-if="item.start_time == '-28800'">
                            <i class="iconfont icon-icon_yxq"></i>长期有效
                        </p>
                        <p v-else-if="item.validity_day > 0">
                            <i class="iconfont icon-icon_yxq"></i>有效期{{ item.validity_day }}天
                        </p>
                        <p v-else>
                            <i class="iconfont icon-icon_yxq"></i>有效期
                            {{ item.start_time | formatimes("yyyy-MM-dd") }}~{{
                                item.end_time | formatimes("yyyy-MM-dd")
                            }}
                        </p>
                    </div>
                </li>
            </ul>
            <div class="studyCoursekdpage" style="margin-top: 30px">
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                    :current-page="currentPage41" :page-sizes="[20, 30, 40, 50]" :page-size="20"
                    layout="total, sizes, prev, pager, next, jumper" :total="totaol">
                </el-pagination>
            </div>
        </div>
        <div class="courseWrapparentdivdi1zwkc" v-else>
            <p>没有找到相关课程~换个条件吧</p>
        </div>
        <div class="courseWrapparentdivdi1sjyc" v-if="code != 0">
            <p>好像出错了</p>
            <span @click="refresh">刷新重试</span>
        </div>
        <div class="top" @click="backTop" v-if="btnFlag">TOP</div>
    </div>
</template>

<script>
import {
    courseList,
    allinfo,
    majorinfofirst,
    majorinfo,
    subjectinfo,
    faculty,
} from "./assets/api.js";
import { getstudentinfos } from "../../../api/api";
export default {
    name: "course_course",
    data () {
        return {
            identity: "",
            seachflag: true,
            checkflag: false,
            isZh: [],
            btnFlag: false,
            visibleHead: true,
            visibleShow: true,
            currentPage41: 1,
            courseTypelist: [
                { name: "综合", id: 1, ids: "sorting" },
                { name: "人气", id: 2, ids: "hot" },
                { name: "新上线", id: 3, ids: "new" },
            ],
            courseTypelistindex: 1,
            list: {},
            listindex: 1,
            courseindex: 0,
            yxindex: 0,
            xkindex: 0,
            courseData: [],
            firstmajorlist: [],
            twomajorlist: [],
            subjectinfoList: [],
            showflag: false,
            showname: "",
            classflag: 0,
            clasflag: "",
            seachdata: [],
            code: "",
            forms: {
                first_subject_id: "",
                second_subject_id: "",
                third_subject_id: "",
                course_type: "",
                teacher_only: 0,
                title: "",
                sort: "",
                page: 1,
                limit: 20,
            },
            totaol: "",
            course_card_config: ''
        };
    },
    mounted () {
        this.$data.forms = this.$options.data().forms;
        let info = sessionStorage.getItem('infos')
        if (info != null) {
            getstudentinfos().then((res) => {
                if (res.code == 0) {
                    this.$addStorageEvent(2, "infos", JSON.stringify(res.data));
                    this.identity = JSON.parse(sessionStorage.getItem("infos")).identity;
                }
            });
        }

        let metas = window.sessionStorage.getItem("meta")
            ? JSON.parse(window.sessionStorage.getItem("meta"))
            : [];
        metas.map(item => {
            this.filters(item)
        })
        this.allinfo();
        this.$nextTick(() => {
            var arrList = document.getElementsByClassName("oullist");
            if (arrList.length > 0) {
                for (var i = 0; i < arrList.length; i++) {
                    this.isZh.push(false);
                }
            }
        });
        if (this.$route.query.searchText) {
            this.forms.title = this.$route.query.searchText;
        } else {
            this.forms.title = "";
        }
        if (this.$route.query.first_subject_id) {
            this.forms.first_subject_id = this.$route.query.first_subject_id;
        } else {
            this.forms.first_subject_id = "";
        }
        if (this.$route.query.second_subject_id) {
            this.forms.second_subject_id = this.$route.query.second_subject_id;
        } else {
            this.forms.second_subject_id = "";
        }
        if (this.$route.query.sort) {
            this.forms.sort = this.$route.query.sort;
            if (this.forms.sort == "hot") {
                this.courseTypelistindex = 2;
            }
            if (this.forms.sort == "new") {
                this.courseTypelistindex = 3;
            }
        } else {
            this.forms.sort = "";
        }
        this.getList();
    },
    watch: {
        $route: {
            handler: function (val) {
                this.forms.title = val.query.searchText;
                this.getList();
            },
            deep: true,
        },
    },
    methods: {
        learns (id) {
            this.$router.push({
                path: "/learn",
                query: {
                    course_id: id,
                },
            });
        },
        teacher (data) {
            if (data) {
                this.forms.teacher_only = 1;
            } else {
                this.forms.teacher_only = 0;
            }
            this.getList();
        },
        refresh () {
            this.$router.push("./search");
        },
        leaveflag (data) {
            data.show = false;
        },
        leavflag (data) {
            data.show = false;
        },
        gesinfo (data) {
            if (data.menu_id == 0) {
                this.forms.first_subject_id = "";
                this.forms.second_subject_id = "";
                this.forms.third_subject_id = "";
            } else {
                this.forms.second_subject_id = data.menu_id;
            }
            this.getList();
            this.filters(data);
            this.clasflag = data.menu_id;
            if (data.child.length > 0) {
                data.show = true;
            }
        },
        allinfo () {
            allinfo().then((res) => {
                if (res.code == 0) {
                    let params0 = { id: "", name: "全部", show_type: "1" };
                    res.data.coursename.unshift(params0);
                    this.list.coursename = res.data.coursename;
                }
            });
            faculty().then((resa) => {
                if (resa.code == 0) {
                    let params2 = {
                        id: "",
                        menu_id: "",
                        name: "全部",
                        show_type: "5",
                        child: [],
                    };
                    resa.data.subject_tree.unshift(params2);
                    this.list.subject_tree = resa.data.subject_tree;
                }
            });
        },

        majorinfofirst (data) {
            let params = {
                faculty_id: data,
            };
            majorinfofirst(params).then((res) => {
                if (res.code == 0) {
                    this.firstmajorlist = res.data.majorinfos;
                    this.firstmajorlist.map((item) => {
                        this.$set(item, "show", false);
                    });
                    let quanbu = { id: "", name: "全部", show_type: "3" };
                    this.firstmajorlist.unshift(quanbu);
                }
            });
        },

        getList () {
            let params = this.forms;
            courseList(params).then((res) => {
                this.code = res.code;
                if (res.code == 0) {
                    this.courseData = res.data.row;
                    this.totaol = res.data.num;
                    // let info_name = JSON.parse(sessionStorage.getItem('info_name'))
                    if (sessionStorage.getItem('schoolInfo') != null) {
                        this.course_card_config = JSON.parse(sessionStorage.getItem('schoolInfo')).course_card_config
                        this.course_card_config = this.course_card_config == '' ? '' : JSON.parse(this.course_card_config)
                    }
                    else if(sessionStorage.getItem('info_name') !=null){
                        this.course_card_config = JSON.parse(sessionStorage.getItem('info_name')).course_card_config
                        this.course_card_config = this.course_card_config == '' ? '' : JSON.parse(this.course_card_config)
                    }
                    
                }
            });
        },
        handleSizeChange1 (val) {
            this.forms.limit = val;
            this.getList();
        },
        handleCurrentChange1 (val) {
            this.forms.page = val;
            this.getList();
        },
        //划过出现综合筛选
        mouseo () {
            if (!this.visibleHead) {
                this.visibleShow = true;
            }
        },
        //课程人气
        courseTypelistClick (data) {
            this.courseTypelistindex = data.id;
            this.forms.sort = data.ids;
            this.getList();
        },
        filters (data) {
            if (data.id == 0 || data.menu_id == 0) {
                this.seachdata.map((item, index) => {
                    if (data.show_type.substr(0, 1) == item.show_type.substr(0, 1)) {
                        this.seachdata.splice(index, 1);
                    }
                });
            } else {
                if (this.seachdata.length > 0) {
                    this.seachdata.map((item, index) => {
                        if (data.show_type.substr(0, 1) == item.show_type.substr(0, 1)) {
                            this.seachdata.splice(index, 1);
                        }
                    });
                }
                this.seachdata.push(data);
            }
        },
        del (data, index) {
            this.seachdata.splice(index, 1);
            if (data.show_type.substr(0, 1) == 1) {
                this.courseindex = 0;
                this.forms.course_type = "";
            } else if (data.show_type.substr(0, 1) == 2) {
                this.yxindex = 0;
                this.forms.faculty_id = "";
            } else if (data.show_type.substr(0, 1) == 3) {
                this.classflag = 0;
                this.forms.major_pid = "";
                this.forms.major_id = "";
            } else if (data.show_type.substr(0, 1) == 5) {
                this.clasflag = 0;
                this.xkindex = 0;
                this.showflag = false;
                this.forms.first_subject_id = "";
                this.forms.second_subject_id = "";
                this.forms.third_subject_id = "";
            }
            this.getList();
        },
        clears () {
            this.seachdata = [];
            this.courseindex = 0;
            this.yxindex = 0;
            this.classflag = 0;
            this.xkindex = 0;
            this.forms = {};
            this.getList();
        },
        //课程类型
        courseClick (data) {
            this.courseindex = data.id;
            this.filters(data);
            if (data.id == 0) {
                this.forms.course_type = "";
            } else {
                this.forms.course_type = data.id;
            }
            this.getList();
        },
        //课程院系
        yxClick (data) {
            this.yxindex = data.id;
            this.majorinfofirst(data.id);
            this.filters(data);
            if (data.id == 0) {
                this.forms.faculty_id = "";
            } else {
                this.forms.faculty_id = data.id;
            }
            this.getList();
        },
        //学科
        xkClick (data) {
            if (data.menu_id == 0) {
                this.forms.first_subject_id = "";
                this.forms.second_subject_id = "";
                this.forms.third_subject_id = "";
            } else {
                this.forms.first_subject_id = data.menu_id;
            }
            this.getList();
            this.filters(data);
            if (data.child.length > 0) {
                this.showflag = true;
            } else {
                this.showflag = false;
            }
            this.showname = data.name;
            this.xkindex = data.menu_id;
            this.subjectinfoList = data.child;
            this.subjectinfoList.map((item) => {
                this.$set(item, "show", false);
            });
        },
        twomase (data) {
            this.filters(data);
            if (data.id == 0) {
                this.forms.major_pid = "";
                this.forms.major_id = "";
            } else {
                this.forms.major_id = data.id;
            }
            this.getList();
        },
        thriseach (data) {
            this.filters(data);
            if (data.menu_id == 0) {
                this.forms.first_subject_id = "";
                this.forms.second_subject_id = "";
                this.forms.third_subject_id = "";
            } else {
                this.forms.third_subject_id = data.menu_id;
            }
            this.getList();
        },
        clickfun (data) {
            this.isZh[data] = !this.isZh[data];
            this.isZh.splice(data, 1, this.isZh[data]);
        },
        backTop () {
            const that = this;
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop =
                    that.scrollTop + ispeed;
                if (that.scrollTop === 0) {
                    clearInterval(timer);
                }
            }, 16);
        },
    },
    destroyed () {
        // window.sessionStorage.removeItem('meta')
    },
};
</script>

<style scoped lang="scss">
@import "./assets/search.scss";

.courseWrapdivresulttjp {
    i {
        display: inline-block;
        background: url("./assets/images/offcn_yxbbg61.png") no-repeat left top;
        width: 18px;
        height: 18px;
        vertical-align: top;
        margin-top: 6px;
        margin-left: 10px;
    }
}

// .courseWrapdivresulttjp:hover {
//   color: #fff !important;
//   background: #507fff;
//   i {
//     background: url("./assets/images/offcn_yxbbg61_hover.png") no-repeat left
//       top;
//   }
// }
.courseWrapparentdivdi1zwkc {
    background: url("./assets/images/offcn_yxbbg63.png") no-repeat left top;
    width: 640px;
    height: 359px;
    margin: 70px auto 160px;

    p {
        text-align: center;
        font: 16px/20px "Microsoft Yahei";
        color: #666666;
        padding-top: 226px;
    }
}

.courseWrapparentdivdi1sjyc {
    background: url("./assets/images/offcn_yxbbg64.png") no-repeat left top;
    width: 640px;
    height: 359px;
    margin: 70px auto 160px;

    p {
        text-align: center;
        font: 16px/20px "Microsoft Yahei";
        color: #666666;
        padding-top: 226px;
    }

    span {
        display: block;
        width: 126px;
        height: 32px;
        border-radius: 16px;
        background: #507fff;
        color: #fff;
        font: 14px/32px "Microsoft Yahei";
        text-align: center;
        margin: 15px auto 0;
        cursor: pointer;
    }
}
</style>
