<template>
    <div class="detailWarp">
        <div class="goBack">
            <div class="back" @click="$router.push('/orderlist')">返回</div>
            <div class="nav">
                <router-link to="/">首页</router-link> &gt; <router-link to="/orderlist">我的订单</router-link> &gt;
                <span>订单详情</span>
            </div>
        </div>
        <div class="orderStatus">
            <div class="statusLeft">订单状态</div>
            <div class="statusRight"><span v-if="orderInfo.order_status == 0 && countDown != '0'">请在<i>{{ countDown
                        }}</i>内支付完毕</span> {{
                            orderInfo.order_status == 1 ? '已支付' : orderInfo.order_status == 0 ? '待支付' : orderInfo.order_status == 4 && orderInfo.cancel_time !=0?'已取消':'支付超时，已自动取消' }}</div>
        </div>
        <div class="shopDetail">
            <h3>商品信息</h3>
            <p class="shopClass">商品类型：{{ orderInfo.goods_type == 1 ? '课程' : '会员' }}</p>
            <div class="shop">
                <img v-if="orderInfo.image != ''" :src="orderInfo.image" alt="">
                <img v-else :src="require('../../../../assets/images/nocourse.png')" alt="" />
                <p class="shopTitle">{{ orderInfo.goods_title }}</p>
                <div class="shopPrice">
                    <p><b></b><span>￥{{ orderInfo.goods_price }}</span></p>
                    <!-- <p><b>双11优惠：</b><span>-￥99.99</span></p> -->
                    <p><b class="realityPrice">实付：</b><span class="price">￥{{ orderInfo.buy_price }}</span></p>
                </div>
            </div>
        </div>
        <div class="orderInfo">
            <h3>订单信息</h3>
            <p>订单编号：{{ orderInfo.order_code }}</p>
            <p>创建时间：{{ orderInfo.order_time | formatimes('yyyy-MM-dd hh:mm:ss') }}</p>
            <p v-if="orderInfo.order_status == 4">取消时间：<span v-if="orderInfo.cancel_time != '0'">{{ orderInfo.cancel_time | formatimes('yyyy-MM-dd hh:mm:ss') }}</span><span v-else>{{ (Number(orderInfo.order_time)+900) | formatimes('yyyy-MM-dd hh:mm:ss')  }}</span></p>
            <p v-if="orderInfo.order_status==1">支付时间：{{ orderInfo.pay_time | formatimes('yyyy-MM-dd hh:mm:ss') }}</p>
            <p v-if="orderInfo.order_status==1">支付方式：{{ orderInfo.pay_type == 1 ? '在线支付' : orderInfo.pay_type == 2 ? '银行付款' : 'ios余额支付' }}</p>
        </div>
        <div class="orderBtn" v-if="orderInfo.order_status == 4">
            <el-button round class="deleteOrder" @click="delOrder(orderInfo.id)">删除订单</el-button>
            <el-button type="primary" round
                @click="reorderAgain(orderInfo.goods_id, orderInfo.goods_with_id)">重新下单</el-button>
        </div>
        <div class="orderBtn" v-if="orderInfo.order_status == 0">
            <el-button round class="deleteOrder" @click="closeOrder(orderInfo.id)">取消订单</el-button>
            <el-button type="primary" round @click="payNow(orderInfo.order_id)">立即支付</el-button>
        </div>
        <form :action="submiturl" method="post" ref="pay" name="pay" style="display: none">
            <div v-for="(item, index) in postdata" :key="index">
                <input type="text" :name="index" :value="postdata[index]" />
            </div>
        </form>
    </div>
</template>

<script>
import { orderDetails, verifyOrder, bdxtPayData, delOrder,closeOrder } from '../assets/api'
export default {
    name: 'orderdetail',
    data () {
        return {
            orderInfo: {},
            countDown: '',
            timer: null,
            submiturl: '',
            postdata: {}
        }
    },
    mounted () {
        this.getDetails()
    },
    methods: {
        // 关闭/取消订单
        closeOrder(id){
            let params = {
                order_id: id
            }
            this.$confirm('您是否要关闭此订单?', '提醒', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                closeOrder(params).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '关闭成功!'
                        });
                        this.getDetails()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消关闭'
                });
            });
        },
        // 删除订单
        delOrder (id) {
            let params = {
                order_id: id
            }
            this.$confirm('您确定要删除此订单吗?', '提醒', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delOrder(params).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        setTimeout(() => {
                            this.$router.push('/orderlist')
                        }, 1000);
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 去支付
        payNow (id) {
            let params = {
                orderId: id
            }
            verifyOrder(params).then(res => {
                if (res.code == 0) {
                    let data = {
                        orderId: id,
                        client_type: 1
                    }
                    bdxtPayData(data).then(res => {
                        if (res.code == 0) {
                            this.submiturl = res.data.submiturl
                            this.postdata = res.data.postdata
                            this.$nextTick(() => {
                                this.$refs.pay.submit();
                            });
                        }
                    })
                }
            })
        },
        // 获取订单详情
        getDetails () {
            let params = {
                order_id: this.$route.query.order_id
            }
            orderDetails(params).then(res => {
                if (res.code == 0) {
                    this.orderInfo = res.data.order
                    this.timer = setInterval(() => {
                        this.updateCountdown(this.orderInfo.order_time)
                    }, 1000);
                }
            })
        },
        // 重新下单
        reorderAgain (course_id, claim_id) {
            this.$router.push({
                path: '/pay',
                query: {
                    course_id,
                    claim_id
                }
            })
        },
        // 倒计时
        updateCountdown (order_time) {
            let currentTime = parseInt(new Date().getTime() / 1000);
            let targetTime = parseInt(order_time) + 900
            let timeLeft = targetTime - currentTime;
            if (timeLeft >= 0) {
                const minutes = Math.floor((timeLeft / 60) % 60);
                const seconds = Math.floor((timeLeft) % 60);
                // 格式化显示
                this.countDown = (minutes < 10 ? '0' : '') + minutes + "分" + (seconds < 10 ? '0' : '') + seconds + '秒';
            } else {
                clearInterval(this.timer);
                this.countDown = "0";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.detailWarp {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;

    .orderBtn {
        text-align: right;
        margin-top: 20px;
    }

    .deleteOrder {
        &:hover {
            background: #fff;
            border: 1px solid var(--change-color);
            color: var(--change-color)
        }
    }

    .goBack {
        display: flex;

        .back {
            width: 52px;
            height: 28px;
            border: 1px solid #dfdfdf;
            border-radius: 14px;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            color: #888;
            cursor: pointer;
        }

        .nav {
            line-height: 28px;
            flex: 1;
            font-size: 14px;
            color: #888;
            padding-left: 14px;

            a {
                color: #888;
            }
        }
    }

    .orderStatus {
        height: 50px;
        background: #F5F7FA;
        margin-top: 12px;
        display: flex;

        .statusLeft {
            flex: 1;
            line-height: 50px;
            font-size: 16px;
            padding-left: 20px;
            font-weight: bold;
        }

        .statusRight {
            flex: 1;
            line-height: 50px;
            text-align: right;
            padding-right: 20px;

            span {
                font-size: 14px;

                i {
                    color: var(--change-color);
                }
            }
        }
    }

    .shopDetail {
        height: 182px;
        padding: 20px;
        border: 1px solid #EFF2F5;

        h3 {
            font-size: 16px;
            font-weight: normal;
        }

        .shopClass {
            font-size: 14px;
            line-height: 34px;
        }
    }

    .shop {
        display: flex;
        margin-top: 4px;

        img {
            width: 200px;
            height: 114px;
            border-radius: 4;
        }

        .shopTitle {
            flex: 5;
            font-size: 16px;
            line-height: 28px;
            padding: 10px 0 0 24px;
        }

        .shopPrice {
            flex: 3;
            text-align: right;

            p {
                display: flex;
                font-size: 14px;
                line-height: 28px;

                span {
                    flex: 1;
                }

                b {
                    flex: 2;
                    font-weight: normal;
                }

                .realityPrice {
                    font-size: 16px;
                    font-weight: bold;
                }

                .price {
                    font-size: 16px;
                    color: #94070A;
                    font-weight: bold;
                }
            }


        }
    }

    .orderInfo {
        border: 1px solid #EFF2F5;
        padding: 10px 20px 20px 20px;
        margin-top: 10px;

        p {
            font-size: 12px;
            line-height: 24px;
        }

        h3 {
            font-size: 16px;
            font-weight: normal;
            line-height: 36px;
        }
    }
}
</style>