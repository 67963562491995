<template>
  <div class="xWrap">
    <div class="topWrap">
      <div class="topContent">
        <div class="topBread">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="topCrumb">
            <el-breadcrumb-item :to="{ path: '/xhome' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>认证</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="topTitle">
          <div class="topTLeft">
            <h2>{{ infoData.title }}</h2>
            <div>
              {{ infoData.subheading }}
            </div>
          </div>
          <div class="topTRight">
            <div>报名费用{{ infoData.price }}元</div>
            <el-button round type="primary" @click="signs">立即报名</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomWrap">
      <div class="bottomContent">
        <div class="bottomLeft">
          <div class="titleWrap">
            <img src="../assets/images/infoIcon.png" alt="" />
            课程介绍
          </div>
          <div class="detailWrap">
            <img
              src="../assets/images/detailIcon.png"
              alt=""
              class="detailIcon"
            />
            <p>
              {{ infoData.introduction }}
            </p>
          </div>
          <div
            :class="infoData.big_pic_list.length > 1 ? 'imgMore' : 'imgWrap'"
          >
            <img
              v-for="(item, index) in infoData.big_pic_list"
              :key="index"
              :src="item"
              alt=""
            />
          </div>
        </div>
        <div class="bottomRight">
          <div
            class="rightItem"
            v-for="(item, index) in infoData.course_customize"
            :key="index"
          >
            <span class="itemLeft">{{ item.title.value }}</span>
            <span class="itemRight">{{ item.content.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            :show-close="true"
            width="360px"
    >
      <div class="contMaindialog">
        <h6 class="contMaindialogh6">该认证不对个人开放，请联系报名负责人</h6>
      </div>
    </el-dialog>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible1"
            :show-close="true"
            width="360px"
    >
      <div class="contMaindialog" style="text-align: center">
        <h6 class="contMaindialogh6">查询到有报名记录</h6><br>
        <el-button type="primary" round @click="golook">去看看</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { xmCourseInfo } from "@/views/pages/xiaomi/assets/api";
export default {
  data() {
    return {
      infoData: {},
      dialogVisible:false,
      dialogVisible1:false
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      let params = {
        course_id: this.$route.query.course_id, //9776
      };
      xmCourseInfo(params).then((res) => {
        if (res.code == 0) {
          this.infoData = res.data;
        }
      });
    },
    signs() {
    	if(sessionStorage.getItem('infos')) {
          if(this.infoData.is_apply==0){
            this.dialogVisible = true
          }else{
            this.dialogVisible1 = true
          }
		}else{
          const hostname = window.location.hostname
          window.location.href =
                  '//' + hostname + '/login/#/'
		}
	},
    golook(){
      this.$message('没有认证模块')
    }
  },
};
</script>

<style scoped lang="scss">
  .contMaindialogh6{ font-size: 13px; text-align: center; font-weight: normal}
* {
  box-sizing: border-box;
}
.xWrap {
  font-size: 14px;
  box-sizing: border-box;
  padding-bottom: 10px;
  .topWrap {
    width: 100%;
    height: 229px;
    background: url("../assets/images/topWrap.png") no-repeat;
    .topContent {
      width: 1360px;
      margin: 0 auto;
      color: #fff;
      padding-top: 20px;
      .topBread {
        margin-bottom: 44px;
      }
      .topTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .topTLeft {
          width: 783px;
          h2 {
            font-size: 26px;
            margin-bottom: 18px;
          }
          div {
            line-height: 26px;
          }
        }
        .topTRight {
          text-align: right;
          div {
            font-size: 16px;
            margin-bottom: 15px;
          }
          .el-button {
            width: 120px;
            height: 44px;
            border-radius: 500px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .bottomWrap {
    padding-top: 35px;
    .bottomContent {
      width: 1360px;
      margin: 0 auto;
      overflow: hidden;
      .bottomLeft {
        float: left;
        width: 985px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 0 20px rgba(5, 17, 55, 0.08);
        padding: 40px 50px;
        margin-right: 30px;
        .titleWrap {
          margin-bottom: 28px;
          font-size: 20px;
          color: #333;
          display: flex;
          align-items: center;
          img {
            width: 27px;
            height: 20px;
            margin-right: 10px;
          }
        }
        .detailWrap {
          // margin-top: 5px;
          padding: 34px 25px 20px;
          box-sizing: border-box;
          font-size: 14px;
          background: #f8f8f8;
          border-radius: 6px;
          position: relative;
          .detailIcon {
            position: absolute;
            right: 24px;
            top: -10px;
          }
        }
        .imgWrap {
          margin-top: 20px;
          width: 100%;
          img {
            display: inline-block;
            width: 100%;
            // border-radius: 10px;
          }
        }
        .imgMore {
          margin-top: 20px;
          width: 100%;
          img {
            display: inline-block;
            width: 100%;
            margin-bottom: 10px;
          }
          img:first-child {
            border-radius: 10px 10px 0 0;
          }
          img:last-child {
            border-radius: 0 0 10px 10px;
            margin-bottom: 0;
          }
        }
      }
      .bottomRight {
        float: left;
        width: 345px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 0 20px rgba(5, 17, 55, 0.08);
        padding: 36px 30px;
        .rightItem {
          font-size: 14px;
          line-height: 26px;
          margin-bottom: 30px;
          overflow: hidden;
          &:last-child {
            margin-bottom: 0;
          }
          .itemLeft {
            float: left;
            margin-right: 16px;
            width: 56px;
            color: #888;
          }
          .itemRight {
            float: left;
            width: 212px;
          }
        }
      }
    }
  }
}
</style>
