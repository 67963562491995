<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose" custom-class="dialogView">
            <div class="dialog_content">
                <!-- <i class="iconfont icon-yunshangchuan"></i>
                <p class="dialog_text">未升级认证服务</p>
                <p class="dialog_msg">请先升级吧</p>
                <span>去升级</span> -->
                <!-- <i class="iconfont icon-gouwuche"></i>
                <p class="dialog_text">未购买课程</p>
                <p class="dialog_msg">请先购买吧</p>
                <span>去购买</span> -->
                <!-- <i class="iconfont icon-buhuanlingdengjizhengshu"></i> 
                <p class="dialog_text">未达证书申请标准</p>
                <p class="dialog_msg">请继续学习吧</p>-->
                <i class="iconfont icon-zhengshu1"></i>
                <p class="dialog_text">证书后期开放申请</p>
                <p class="dialog_msg">请耐心等待</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'view_certificate',
    data(){
        return{
            dialogVisible:false
        }
    },
    methods:{
        openInit(){
            this.dialogVisible = true
        },
        handleClose(){
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .dialogView{
    border-radius:20px;
    padding-bottom:30px;
     .el-dialog__header{
        border: none !important;
        // padding:0;
    }
}

.dialog_content{
    text-align: center;
    i{
        font-size: 100px;
        color:var(--change-color);
    }
    .no_open{

    }
    .dialog_text{
        font-size: 22px;
        font-weight: bold;
        color:#333;
        line-height: 2;
    }
    .dialog_msg{
        line-height: 2;
        font-size: 16px;
        color:#888;
    }
    span{
        display: inline-block;
        width: 126px;
        height: 44px;
        background:var(--change-color);
        color:#fff;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 22px;
        margin:40px 0 0;
    }
}
</style>