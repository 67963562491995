<template>
    <div class="warp">
        <div class="success" v-if="orderInfo!=null && orderInfo.pay_type==1">
            <img src="../assets/images/success.png" alt="">
            <p class="successTitle">支付成功</p>
            <p class="successSubTitle">支付金额：<span>￥{{orderInfo.pay_fee}}</span></p>
            <p class="successDes">感谢您的购买！</p>
            <ul class="successList">
                <li>
                    <h5>开始学习</h5>
                    <p>点击右侧按钮立即开启学习之旅</p>
                    <el-button round type="primary" @click="goStudy(orderInfo.course_id)">开始学习</el-button>
                </li>
                <li>
                    <h5>查看订单</h5>
                    <p>点击右侧按钮立即查看订单信息，您也可以在【我的订单】中查找该交易订单</p>
                    <el-button round type="primary" @click="goOrder">订单详情</el-button>
                </li>
            </ul>
        </div>
        <div class="error" v-else>
            <img src="../assets/images/error.png" alt="">
            <p class="errorTitle">支付失败</p>
            <p class="errorSubTitle">应付金额：<span v-if="orderInfo!=null">￥{{orderInfo.pay_fee}}</span></p>
            <p class="errorDes">点击下方按钮，立即前往订单详情页完成付款或取消订单</p>
            <div class="btn">
                <el-button round type="primary" @click="goOrder">订单详情</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {bdxtQuery} from '../assets/api'
export default {
    name: 'paymentReturn',
    data () {
        return {
            isSuccess:true,
            orderInfo:null
        }
    },
    mounted(){
        let order_code = this.$route.query.ordercode
        bdxtQuery({order_code}).then(res=>{
           if(res.code == 0){
                console.log(res.data);
                this.orderInfo = res.data
           }
        })
    },
    methods: {
        goOrder(){
            this.$router.push('/orderlist')
        },
        goStudy(course_id){
            this.$router.push({
                path:'/learn',
                query:{
                    course_id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.warp {
    width: 1360px;
    height: 742px;
    background: #fff;
    margin: 20px auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .error{
        flex: 1;
        // text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .errorTitle{
            font-size:16px;
            font-weight: bold;
            color:#333;
            line-height:28px;
        }
        .errorSubTitle{
            font-size:16px;
            color:#333;
            line-height:28px;
            span{
                color:var(--change-color);
            }
        }
        .errorDes{
            font-size: 14px;
            color:#999;
            line-height: 28px;
        }
        .btn{
            padding:30px 0 40px;
            /deep/ .el-button.is-round{
                padding:12px 40px;
            }
        }
    }
    .success{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .successTitle{
            font-size:16px;
            font-weight: bold;
            color:#333;
            line-height: 32px;
        }
        .successSubTitle{
            font-size: 16px;
            color: #333;
            line-height:32px;
            span{
                color:var(--change-color);
            }
        }
        .successDes{
            font-size:14px;
            color:#999;
            line-height:32px;
        }
        .successList{
            padding-top:50px;
            li{
                width:1300px;
                height:110px;
                padding:18px 30px;
                box-sizing: border-box;
                border:1px solid #EAEAEA;
                border-bottom:none;
                position: relative;
                h5{
                    font-size: 16px;
                    font-weight: bold;
                    color:#333;
                    line-height:40px;
                }
                p{
                    font-size:14px;
                    color:#333;
                    line-height:38px;
                }
                /deep/ .el-button.is-round{
                    padding:12px 40px;
                    position: absolute;
                    right: 55px;
                    top:32px;
                }
            }
            li:last-child{
                border-bottom:1px solid #EAEAEA;
            }
        }
    }
}
</style>