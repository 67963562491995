<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose" custom-class="dialogView"
            :close-on-click-modal="false">
            <div class="dialog_content">
                <template v-if="certificate_type!=2 && status == 9">
                    <i class="iconfont icon-yunshangchuan"></i>
                    <p class="dialog_text">未升级认证服务</p>
                    <p class="dialog_msg">请先升级吧</p>
                    <span @click="goUpgrade">去升级</span>
                </template>
                <template v-if="certificate_type!=2 && status == 8">
                    <i class="iconfont icon-gouwuche"></i>
                    <p class="dialog_text">未购买课程</p>
                    <p class="dialog_msg">请先购买吧</p>
                    <span @click="goBuy">去购买</span>
                </template>
                <template v-if="certificate_type!=2 && status == 7">
                    <i class="iconfont icon-buhuanlingdengjizhengshu"></i>
                    <p class="dialog_text">未达证书申请标准</p>
                    <p class="dialog_msg">请继续学习吧</p>
                </template>
                <template v-if="certificate_type!=2 && status == 6">
                    <i class="iconfont icon-buhuanlingdengjizhengshu applicationCompleted"></i>
                    <p class="dialog_text">证书申请已结束</p>
                    <p class="dialog_msg">{{ copywriting }}</p>
                </template>
                <template v-if="certificate_type!=2 && status == 5">
                    <i class="iconfont icon-buhuanlingdengjizhengshu"></i>
                    <p class="dialog_text">证书申请未开放</p>
                    <p class="dialog_msg">{{ copywriting }}</p>
                </template>
                <template v-if="certificate_type!=2 && status == 10">
                    <i class="iconfont icon-buhuanlingdengjizhengshu"></i>
                    <p class="dialog_text">证书未开放证书申请</p>
                    <p class="dialog_msg">请刷新页面~</p>
                </template>
                <template v-if="certificate_type == 2">
                    <i class="iconfont icon-zhengshu1"></i>
                    <p class="dialog_text">证书后期开放申请</p>
                    <p class="dialog_msg">请耐心等待</p>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: 'view_certificate',
    data () {
        return {
            dialogVisible: false,
            status: 0,
            copywriting: '',
            certificate_type: 1,
        }
    },
    methods: {
        openInit (certificate_type, status, copywriting) {
            this.dialogVisible = true
            this.certificate_type = certificate_type
            if (certificate_type != 2) {
                this.status = status
                if (this.status == 5 || this.status == 6) {
                    this.copywriting = copywriting
                }
            }
        },
        handleClose () {
            this.dialogVisible = false
        },
        // 去升级
        goUpgrade () {
            this.$emit('goUpgrade')
        },
        // 去购买
        goBuy () {
            this.$emit('goBuy')
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .dialogView {
    border-radius: 20px;
    padding-bottom: 30px;

    .el-dialog__header {
        border: none !important;
        // padding:0;
    }
}

.dialog_content {
    text-align: center;

    i {
        font-size: 100px;
        color: var(--change-color);
    }

    .applicationCompleted {
        color: #CDCDCD;
    }

    .no_open {}

    .dialog_text {
        font-size: 22px;
        font-weight: bold;
        color: #333;
        line-height: 2;
    }

    .dialog_msg {
        line-height: 2;
        font-size: 16px;
        color: #888;
    }

    span {
        display: inline-block;
        width: 126px;
        height: 44px;
        background: var(--change-color);
        color: #fff;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 22px;
        margin: 40px 0 0;
    }
}
</style>