<template>
    <div>
        <el-dialog :visible.sync="stateVisible" width="800px" :before-close="handleClose" custom-class="stateClass">
            <div slot="title">
                <div class="state_title">
                    <i class="iconfont icon-buhuanlingdengjizhengshu"></i> 证书待申请
                </div>
                <!-- <div class="state_msg">恭喜学完《XXX》，可自主申请证书</div> -->
                <div class="state_msg state_kemsg state_ketitle">课程名称：高端艺术鉴赏</div>
                <div class="state_msg state_kemsg">申请日期：2023-09-09 18:22:22</div>
                <!-- <div class="reject">
                    <p>驳回理由</p>
                    学院路街道学清路汉华世界大厦学院路街道学清路汉华世界大厦
                </div> -->
                <div class="user_msg">
                    姓名：<span>王琑儿</span>
                </div>
                <div class="user_msg">
                    邮寄信息：<span>周周 18800000011</span>
                </div>
                <div class="user_msg user_address">
                    <span>福建省 南平市 建阳区 高兴大厦28座</span>
                </div>
            </div>
            <form v-show="false">
                <table style="margin: 0 auto;width:658px;">
                    <tr>
                        <td class="tab_lable"><span>*</span> 姓名 </td>
                        <td width="550"><el-input type="text" placeholder="请输入姓名" v-model="userinfo.username"
                                maxlength="30" show-word-limit></el-input></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td width="550" class="username_msg">该姓名用在证书上，请正确填写</td>
                    </tr>
                </table>
                <el-divider></el-divider>
                <table style="margin: 0 auto;width:658px;" class="tab_address">
                    <tr>
                        <td class="tab_lable tab_info">邮寄信息</td>
                        <td width="550" class="tab_reset"><span @click="resetInfo">清空地址</span></td>
                    </tr>
                    <tr>
                        <td class="tab_lable"><span>*</span> 收货人 </td>
                        <td width="550"><el-input type="text" placeholder="请填写收货人" v-model="userinfo.consignee"
                                maxlength="30" show-word-limit></el-input></td>
                    </tr>
                    <tr>
                        <td class="tab_lable"><span>*</span> 手机号 </td>
                        <td width="550"><el-input type="text" placeholder="请填写收货人手机号" v-model="userinfo.phone"
                                maxlength="11" show-word-limit></el-input></td>
                    </tr>
                    <tr>
                        <td class="tab_lable"><span>*</span> 所在区 </td>
                        <td width="550"><el-cascader v-model="userinfo.area" :options="options"
                                :props="{ expandTrigger: 'hover' }" @change="handleChange"
                                popper-class="area_content"></el-cascader></td>
                    </tr>
                    <tr>
                        <td class="tab_lable td_top"><span>*</span> 详细地址 </td>
                        <td width="550"><el-input type="textarea" placeholder="请填写详细地址（街道、楼牌号等）"
                                v-model="userinfo.areainfo" maxlength="100" show-word-limit :rows="3"></el-input></td>
                    </tr>
                    <tr>
                        <td class="tab_lable td_top"><span>*</span> 上传附件 </td>
                        <td width="550"><el-upload class="upload_file" ref="upload"
                                action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview"
                                :on-remove="handleRemove" :file-list="userinfo.fileList" :auto-upload="false"
                                accept=".pdf">
                                <el-button slot="trigger" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">
                                    <p>请下载附件模板，并按要求填写</p>
                                    <p>上传时请传pdf格式文件，大小不超过1M</p>
                                    <div class="file_icon">
                                        <i class="iconfont icon-doc-line"></i>
                                        附件模板：高中研究性课题报告格式.doc
                                    </div>
                                </div>
                            </el-upload></td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align:center;"><el-button type="primary" round>确认申请</el-button></td>
                    </tr>
                </table>
            </form>
            <div class="certificate_info">
                <p>已传附件：</p>
                <p>1、高中研究性课题报告格式.pdf </p>
                <p>2、高中研究性课题究性课题究性课题究性课题究性课题格式究性究性究性究性.pdf</p>
                <img :src="picUrl" class="certificate_pic">
                <span class="certificate_download" @click="downloadImage">下载证书</span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { regionData } from 'element-china-area-data'
export default {
    name: 'apply_state',
    data () {
        return {
            stateVisible: false,
            options: regionData,
            userinfo: {
                username: '',
                consignee: '',
                phone: '',
                area: '',
                areainfo: '',
                fileList: []
            },
            picUrl: require('../../assets/images/pic.png')
        }
    },
    methods: {
        openInit(){
            this.stateVisible = true
        },
        handleClose () {
            this.stateVisible = false
        },
        handleChange (value) {
            console.log(value);
        },
        handleRemove (file, fileList) {
            console.log(file, fileList);
        },
        handlePreview (file) {
            console.log(file);
        },
        resetInfo () {
            this.userinfo = {
                username: '',
                consignee: '',
                phone: '',
                area: '',
                areainfo: '',
                fileList: []
            }
        },
        downloadImage () {
            let arr = this.picUrl.split('.')
            let suffix = arr[arr.length-1]
            let imageUrl = this.picUrl
            let link = document.createElement('a');
            link.href = imageUrl;
            link.download = 'certificate.' + suffix;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .stateClass {
    border-radius: 20px;
    background: #F8F8F8;

    .el-dialog__header {
        border: none !important;
        background: #F8F8F8;
        border-radius: 20px 20px 0 0;
        padding: 30px;

        .state_title {
            font-size: 26px;
            font-weight: bold;
            color: #333;

            i {
                font-weight: normal;
                font-size: 26px;
                color: var(--change-color);
            }
        }

        .state_msg {
            font-size: 18px;
            color: #666;
            line-height: 48px;

        }

        .user_msg {
            font-size: 18px;
            color: #666;
            line-height: 36px;

            span {
                color: #333;
            }
        }

        .user_address {
            padding-left: 92px;
        }

        .state_kemsg {
            line-height: 36px;
        }

        .state_ketitle {
            padding-top: 6px;
        }

        .reject {
            // width:740px;
            height: 98px;
            border: 1px solid #94070A;
            border-radius: 8px;
            background: #F8EDEB;
            padding: 0 20px;
            color: #94070A;
            font-size: 18px;
            line-height: 36px;
            margin: 10px 0;

            p {
                color: #999999;
                padding-top: 10px;
            }
        }
    }

    .el-dialog__body {
        background: #fff;
        border-radius: 20px;
        padding: 30px;

        .certificate_info {
            p {
                font-size: 16px;
                color: #333;
                line-height: 32px;
            }

            .certificate_pic {
                display: block;
                width: 400px;
                margin: 30px auto;
            }

            .certificate_download {
                cursor: pointer;
                display: block;
                margin: 0 auto;
                width: 124px;
                height: 44px;
                border-radius: 22px;
                background: #95060B;
                color: #fff;
                font-size: 16px;
                text-align: center;
                line-height: 44px;
            }
        }

        .tab_address {
            td {
                padding: 7px 0;
            }

            .tab_info {
                font-size: 16px;
                font-weight: bold;
                color: #333;
            }

            .tab_reset {
                text-align: right;
                font-size: 16px;
                color: #4D63A1;

                span {
                    cursor: pointer;
                }
            }

            .tab_lable {
                padding-right: 14px;
            }

            .td_top {
                vertical-align: top;
                line-height: 48px;
            }

            .el-button--primary {
                width: 126px;
                height: 44px;
            }

            .upload_file {
                .el-button--primary {
                    background: none !important;
                    color: var(--change-color);
                    border: 1px solid var(--change-color) !important;
                    width: 126px;
                    height: 44px;
                }
            }

            .el-upload__tip {
                p {
                    font-size: 14px;
                    color: #999;
                    line-height: 24px;
                }

                .file_icon {
                    font-size: 16px;
                    color: #333;

                    i {
                        font-size: 20px;
                        color: #999;
                    }
                }
            }
        }

        .tab_lable {
            text-align: right;
            font-size: 16px;
            color: #666;
            padding-right: 14px;

            span {
                color: #FE4D67;
            }
        }

        .el-input__inner {
            border: none;
            background: #F8F8F8;
            height: 48px;
        }

        .el-textarea__inner {
            border: none;
            background: #F8F8F8;
            padding: 15px;
        }

        .el-textarea .el-input__count {
            background: none;
        }

        .el-input__count-inner {
            background: none;
        }

        .username_msg {
            color: #F23333;
            font-size: 14px;
            height: 34px;
        }

        .el-cascader {
            width: 100%;
        }

    }
}
</style>
<style lang="scss">
.area_content {

    .el-cascader-node.in-active-path,
    .el-cascader-node.is-active,
    .el-cascader-node.is-selectable.in-checked-path {
        color: var(--change-color);
    }
}
</style>