<template>
    <div :class="footerFlag ? '' : 'h100'" style="height: 100%">
        <div id="app" :class="$route.path == '/video' || $route.path == '/videoplay'
            ? 'h100'
            : ''
            ">
            <!--    :class="WidIstrue ? 'size1000' : ''"-->
            <router-view />
            <template v-if="
                $route.path != '/video' &&
                $route.path != '/videoplay' &&
                $route.path != '/newlearn'
            ">
                <ul class="homeWrapright" v-if="!xiaomiFlag">
                    <!-- <li v-if="siteid != 46">
                        <i class="iconfont icon-home_appdown"></i>
                        <p>APP下载</p>
                        <div class="homeWraprightewm">
                            <span><img src="./assets/images/zg_cbg21.png" alt="" /></span>
                        </div>
                    </li> -->
                    <li v-if="nav_show" @click="backTop" style="border-bottom: none; padding-bottom: 0">
                        <i class="iconfont icon-home_top"></i>
                    </li>
                </ul>
            </template>
        </div>
        <div class="footr" v-if="footerFlag && siteid != 46 && siteid != 68" :class="$route.path == '/notice_detail' ||
            $route.path == '/noticeDetail'
            ? 'posiFooter'
            : ''
            ">
            Copyright©1999-2023 北京中公教育科技有限公司 .All Rights Reserved
            　<a href="https://beian.miit.gov.cn" rel="noopener noreferrer nofollow" target="_blank">京ICP备10218183号-115
            </a>　京ICP证161188号　<a target="_blank" rel="nofollow"
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033985"><img
                    src="https://www.offcn.com/statics/images/zgimg/gongbei.png" alt="" />
                京公网安备 11010802033985号　</a><a
                href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91110108551426892J-SAIC_SHOW_10000091110108551426892J1597915417751&signData=MEUCIH9M0BO8PQFb+MVcIITB7ZpQQLI7g0H9y2TpkODrxFmfAiEApST5zov2Xc1mpEmCDBQekHDo0dl1ZfgoxsJWr8OFfSU="
                target="_blank" rel="nofollow"><img src="https://www.offcn.com/statics/images/zgimg/dzzz.png" alt="" />
                电子营业执照</a> 友情链接 <a target="_blank" rel="nofollow"
                href="https://www.pku.edu.cn/">北京大学</a> | <a target="_blank" rel="nofollow"
                href="https://www.ipku.com/">北大学堂</a>
        </div>
        <div class="footr" v-if="footerFlag && siteid == 46" :class="$route.path == '/notice_detail' ||
            $route.path == '/noticeDetail'
            ? 'posiFooter'
            : ''
            ">
            Copyright©1999-2024 北京中公教育科技有限公司 .All Rights Reserved
            　<a href="http://beian.miit.gov.cn/#/Integrated/index" rel="noopener noreferrer nofollow"
                target="_blank">京ICP备10218183号-119 </a>　京ICP证161188号　<a target="_blank" rel="nofollow"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=%2011010802033494"><img
                    src="https://www.offcn.com/statics/images/zgimg/gongbei.png" alt="" />
                京公网安备 11010802033494号　</a><a
                href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91110108551426892J-SAIC_SHOW_10000091110108551426892J1606892638221&signData=MEQCIHzkQgiy0M58Y5zRRPz3FnbtREGke1rU+QXRB1yaYhedAiAXoSZR/KzuNMH7h4KubDLwwlCeye4n5QAdochM7WCLdg=="
                target="_blank" rel="nofollow"><img src="https://www.offcn.com/statics/images/zgimg/dzzz.png" alt="" />
                电子营业执照</a> 友情链接 <a target="_blank" rel="nofollow"
                href="https://www.pku.edu.cn/">北京大学</a> | <a target="_blank" rel="nofollow"
                href="https://www.ipku.com/">北大学堂</a>
        </div>
        <div class="footr" v-if="footerFlag && siteid == 68" :class="$route.path == '/notice_detail' ||
                $route.path == '/noticeDetail'
                ? 'posiFooter'
                : ''
            ">
            Copyright 版权所有©北京大学智能学院 All Rrights Reserved.
        </div>
    </div>
</template>
<script>
import {
    getstudentinfos,
    checklogin,
    getname,
    schoolextinfo,
} from './api/api.js';
import { setCookie, getCookie } from '@/assets/js/cookie.js';
import { delCookie } from './assets/js/cookie';
export default {
    // HOSTTYPE=PCCLIENT 客户端判断条件
    watch: {
        $route: {
            handler: function (val, o) {
                // console.log(val.path);
                if (
                    val.path == '/' ||
                    val.path == '/learn' ||
                    val.path == '/search'
                ) {
                    this.whh = true;
                } else {
                    this.whh = false;
                }
                if (
                    val.path == '/video' ||
                    val.path == '/videoplay' ||
                    val.path == '/examTicket' ||
                    val.path == '/studentsExam'
                ) {
                    this.footerFlag = false;
                } else {
                    this.footerFlag = true;
                }
                if (val.query.hosttype == 'pcclient') {
                    this.footerFlag = false;
                }
            },
            deep: true,
        },
    },
    data () {
        return {
            windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
            // windowHeight: document.documentElement.clientHeight, //实时屏幕高度
            WidIstrue: false,
            whh: false,
            footerFlag: true,
            nav_show: false,
            gap_time: 0,
            beforeUnload_time: 0,
            xiaomiFlag: false,
            siteid: '',
        }
    },
    created () {
        this.siteid = getCookie('siteid');
        this.gettest()
        setTimeout(() => {
        // this.gettest()
        // console.log(window.location.hash );
        let hash = window.location.hash.slice(2).split('?')[0]
        let info = sessionStorage.getItem('info_name')
        if (
            window.location.host == 'mirenzheng.offcn.com' &&
            !getCookie('siteid')
        ) {
            getname().then((res) => {
                if (res.code == 0) {
                    setCookie('siteid', res.data.school_id);
                }
            });
            this.xiaomiFlag = true;
            this.$router.push('/xhome');
        } else if (hash == 'home' || hash == 'search' || hash == 'learn') {
            // console.log(window.location.hash);
            // this.gettest()

        } else {
            checklogin().then((res) => {
                if (res.code == 0) {
                    // setCookie("siteid", res.data.siteid);
                    setCookie('faculty_id', res.data.faculty_id);
                    if (
                        getCookie('siteid') == 52 ||
                        getCookie('siteid') == 56 ||
                        getCookie('siteid') == 53 ||
                        getCookie('siteid') == 54
                    ) {
                        this.xiaomiFlag = true;
                    }
                    getstudentinfos().then((res) => {
                        if (res.code == 0) {
                            // setCookie("siteid", res.data.siteid);
                            setCookie('faculty_id', res.data.faculty_id);
                            this.$addStorageEvent(
                                2,
                                'infos',
                                JSON.stringify(res.data)
                            );
                            localStorage.setItem('infos',JSON.stringify(res.data))
                        }
                    });
                }
            });
        }
        
            //北大学堂时颜色变化
            if (getCookie('siteid') == '55' || getCookie('siteid') == '46' || getCookie('siteid') == '60' || getCookie('siteid') == '61' || getCookie('siteid') == '62' || getCookie('siteid') == '63' || getCookie('siteid') == '64' || getCookie('siteid') == '65' || getCookie('siteid') == '66' || getCookie('siteid') == '68' || getCookie('siteid') == '69') {
                document.documentElement.style.setProperty(
                    '--change-color',
                    '#95060B'
                );
                document.documentElement.style.setProperty(
                    '--change-hovercolor',
                    '#770509'
                );
                document.documentElement.style.setProperty(
                    '--change-plain-color',
                    '#F9ECED'
                );
                document.documentElement.style.setProperty(
                    '--change-tagline-color',
                    '#ECC8CB'
                );
                document.documentElement.style.setProperty(
                    '--change-darkcolor',
                    '#c1d1fe'
                );
                document.documentElement.style.setProperty(
                    '--change-bgcolor',
                    '#CC4247'
                );
            } else {
                document.documentElement.style.setProperty(
                    "--change-color",
                    "#507fff"
                );
                document.documentElement.style.setProperty(
                    "--change-hovercolor",
                    "#4b78f0"
                );
                document.documentElement.style.setProperty(
                    "--change-plain-color",
                    "#f1f5fe"
                );
                document.documentElement.style.setProperty(
                    "--change-tagline-color",
                    "#cae4ff"
                );
            }
        }, 100);
        if (this.windowWidth <= 1440) {
            this.WidIstrue = true;
        } else {
            this.WidIstrue = false;
        }
        // window.onresize = () => {
        //   return (() => {
        //     window.fullWidth = document.documentElement.clientWidth;
        //     this.windowWidth = window.fullWidth; // 宽
        //     if (this.windowWidth <= 1440) {
        //       this.WidIstrue = true;
        //     } else {
        //       this.WidIstrue = false;
        //     }
        //   })();
        // };
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll);
        // window.addEventListener("beforeunload", () => this.beforeunloadHandler());
        // window.addEventListener("unload", () => this.unloadHandler());
        // getstudentinfos().then((res) => {
        // 	if (res.code == 0) {
        // 		setCookie('siteid', res.data.siteid);
        // 		setCookie('faculty_id', res.data.faculty_id);
        // 		this.$addStorageEvent(2, 'infos', JSON.stringify(res.data));
        // 	}
        // });
    },
    // destroyed() {
    //   // 移除监听
    //   window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
    //   window.removeEventListener("unload", () => this.unloadHandler());
    // },
    methods: {
        async gettest () {
            let res = await getname()
            if (res == 0) {
                this.siteid = res.data.school_id;
                setCookie('siteid', res.data.school_id);
                // setCookie('siteid', 55);
                sessionStorage.setItem('tourist_switch', res.data.tourist_switch)
                sessionStorage.setItem('info_name', res.data)
            }
        },
        yzdl () {
            checklogin().then((res) => {
                if (res.code == 0) {
                    // setCookie("siteid", res.data.siteid);
                    setCookie('faculty_id', res.data.faculty_id);
                    getstudentinfos().then((res) => {
                        if (res.code == 0) {
                            // setCookie("siteid", res.data.siteid);
                            setCookie('faculty_id', res.data.faculty_id);
                            this.$addStorageEvent(
                                2,
                                'infos',
                                JSON.stringify(res.data)
                            );
                        }
                    });
                }
            });
        },
        // beforeunloadHandler() {
        //   this.beforeUnload_time = new Date().getTime();
        //   console.log(4444)
        // },
        // unloadHandler() {
        //   this.gap_time = new Date().getTime() - this.beforeUnload_time;
        //   console.log(123132)
        //   //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
        //   if (this.gap_time <= 10) {
        //     sessionStorage.removeItem("infos");
        //     sessionStorage.removeItem("course");
        //     sessionStorage.removeItem("str_column");
        //     sessionStorage.removeItem("column_id");
        //     delCookie("siteid");
        //     delCookie("infos");
        //     delCookie("faculty_id");
        //   } else {
        //
        //   }
        // },
        backTop () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        handleScroll () {
            let scrollTop = document.documentElement.scrollTop;
            if (scrollTop >= 10) {
                this.nav_show = true;
            } else {
                this.nav_show = false;
            }
        },
    },
};
</script>
<style lang="scss">
.h100 {
    height: 100%;
}

.footr {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #868686;
    height: 30px;
    line-height: 30px;
    clear: both;

    a {
        color: #868686;

        &:hover {
            color: #868686;
        }
    }
}

//右侧悬浮
.homeWrapright {
    position: fixed;
    right: 0;
    top: 80%;
    transform: translateY(-50%);
    background: #fff;
    /*padding: 3px 8px;*/
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    li {
        width: 48px;
        height: 42px;
        cursor: pointer;
        padding: 12px 8px;
        text-align: center;
        border-bottom: 1px solid #f1f1f1;
        position: relative;

        p {
            font: 12px/16px 'Microsoft YaHei';
            color: #333333;
            margin-top: 6px;
        }

        i {
            font: 20px/24px 'Microsoft YaHei';
            color: #666666;
        }

        .homeWraprightewm {
            display: none;
            position: absolute;
            left: -134px;
            top: 0;
            width: 110px;
            height: 110px;
            border-radius: 8px;
            text-align: center;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

            span {
                display: block;
                width: 96px;
                margin: 0 auto;
                height: 96px;
                padding-top: 7px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover {

            p,
            i {
                color: var(--change-color);
            }

            .homeWraprightewm {
                display: block;
            }
        }
    }
}

.posiFooter {
    position: absolute;
    bottom: 0;
}
</style>
