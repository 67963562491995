<template>
    <div class="payWarp">
        <div class="payNav">
            <span @click="goBack">返回</span> 确认订单
        </div>
        <div class="payMain" v-if="courseinfo != null">
            <div class="goodsTitle">商品信息</div>
            <div class="goodsInfo">
                <img :src="courseinfo.images" />
                <h6>{{ courseinfo.title }}</h6>
                <p>商品售价：</p>
                <div>
                    ￥{{ courseinfo.price }}<br>
                    <span>合计：￥</span><b>{{ courseinfo.price }}</b>
                </div>
            </div>
            <form :action="submiturl" method="post" ref="pay" name="pay" style="display: none">
                <div v-for="(item, index) in postdata" :key="index">
                    <input type="text" :name="index" :value="postdata[index]" />
                </div>
            </form>
            <div class="goodsBottom">
                <el-radio v-model="radio" label="1"></el-radio>
                <span>我已阅读并同意<i @click="openGuide">《北大学堂购课售后须知》</i></span>
                <el-button type="primary" round @click="payment">提交订单</el-button>
            </div>
        </div>
        <buyGuide ref="buyGuide" @agree="agree"></buyGuide>
    </div>
</template>

<script>
import buyGuide from './components/buyGuide.vue';
import { getOrderInformation, placeOrder } from './assets/api'
import $ from 'jquery'
export default {
    name: 'pay',
    components: {
        buyGuide
    },
    data () {
        return {
            goodsImg: require('@p/pay/assets/images/goodsimg.png'),
            radio: '0',
            courseinfo: null,
            submiturl: '',
            postdata: {}
        }
    },
    mounted () {
        this.orderInformation()
    },
    methods: {
        // 去支付
        payment () {
            if (this.radio != 1) {
                this.$refs.buyGuide.openInit()
            } else {
                let params = {
                    claim_id: this.$route.query.claim_id,
                    course_id: this.$route.query.course_id,
                    client_type: 1
                }
                placeOrder(params).then(res => {
                    if (res.code == 0) {
                        this.submiturl = res.data.pay_data.submiturl
                        this.postdata = res.data.pay_data.postdata
                        this.$nextTick(() => {
                            this.$refs.pay.submit();
                        });
                    }
                })
            }
        },
        goBack () {
            this.$router.go(-1)
        },
        agree (e) {
            this.radio = '1'
        },
        openGuide () {
            this.$refs.buyGuide.openInit()
        },
        orderInformation () {
            let params = {
                claim_id: this.$route.query.claim_id,
                course_id: this.$route.query.course_id
            }
            getOrderInformation(params).then(res => {
                console.log(res);
                if (res.code == 0) {
                    this.courseinfo = res.data.courseinfo
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.payWarp {
    width: 1360px;
    margin: 0 auto;

    .payNav {
        font-size: 14px;
        color: #888;
        line-height: 28px;
        padding: 20px 0;

        span {
            display: inline-block;
            width: 52px;
            height: 28px;
            border: 1px solid #DFDFDF;
            background: #fff;
            border-radius: 14px;
            text-align: center;
            cursor: pointer;
            margin-right: 14px;
        }
    }

    .payMain {
        width: 1360px;
        height: 590px;
        background: #fff;
        margin: 0 auto;
        border-radius: 8px;
        padding: 30px;
        box-sizing: border-box;

        .goodsTitle {
            font-size: 16px;
            color: #333;
            background: #F5F6F6;
            line-height: 50px;
            text-indent: 28px;
        }

        .goodsInfo {
            height: 136px;
            border: 1px solid #EAEAEA;
            border-top: none;
            display: flex;
            padding: 30px;
            box-sizing: border-box;

            img {
                width: 136px;
                height: 76px;
                border-radius: 6px;
            }

            h6 {
                width: 255px;
                padding-left: 20px;
                font-size: 16px;
                line-height: 30px;
                height: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                font-weight: normal;
                padding-top: 8px;
            }

            p {
                font-size: 14px;
                width: 100px;
                padding-left: 520px;
                padding-top: 8px;
                line-height: 30px;
            }

            div {
                flex: 1;
                padding-top: 8px;
                text-align: right;
                line-height: 30px;
                font-size: 14px;
                font-weight: bold;

                span {
                    display: inline-block;
                    padding-top: 8px;
                    font-weight: normal;
                    color: var(--change-color);
                }

                b {
                    display: inline-block;
                    padding-top: 8px;
                    color: var(--change-color);
                    font-size: 20px;
                }
            }
        }

        .goodsBottom {
            text-align: right;
            padding: 40px 26px 0 0;

            span {
                padding: 0 10px;
                font-size: 14px;

                i {
                    color: var(--change-color);
                    cursor: pointer;
                }
            }

            .el-radio {
                margin-right: 0 !important;
            }

            /deep/ .el-radio__input.is-checked .el-radio__inner {
                border-color: var(--change-color);
                background: var(--change-color);
            }

            /deep/ .el-radio__label {
                display: none;
            }
        }
    }
}
</style>