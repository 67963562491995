<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="670px" :before-close="handleClose" custom-class="buyStyle" :close-on-click-modal="false">
            <div slot="title">
                <div class="guideTitle">北大学堂SaaS平台课程认证须知</div>
            </div>
            <div class="guideContent">
                <h3>第一条：学习模式</h3>
                <p>北大学堂课程根据学员需求采取两种学习模式，包括：</p>
                <p>（1）自主学习，学员随到随学，无开课、结课时间限制；</p>
                <p>（2）认证学习，课程学习完成后，可申请课程认证，审核通过后获得学习证明（电子版）。</p>
                <h3>第二条：课程服务</h3>
                <p>北大学堂为学员提供线上学习平台支持，包括视频、资料、学习证明（电子版）等;</p>
                <h3>第三条：课程有效期</h3>
                <p>1. 自主学习，支持学员无限次回顾学习，课程有效期同SaaS服务有效期；</p>
                <p>2. 认证学习，学习证明（电子版）长期有效，可在官网查询，课程有效期同SaaS服务有效期；</p>
                <h3>第四条：退款规定</h3>
                <p>认证课程为网络虚拟产品，购买后不可转让或退款，请确保您有真实需求再进行购买；</p>
                <h3>第五条：票据申请</h3>
                <p>1. 北大学堂可为认证学习的学员提供北京大学开具的“中央非税收入统一票据（电子）”，该票据可用于报销；</p>
                <p>2. 票据申请说明如下：</p>
                <p>（1）请学员在缴费时填写票据信息；</p>
                <p>（2）票据将在收到申请后7个工作日内发送至电子邮箱，请留意短信提示；</p>
                <p>（3）学员缴费日期如为寒、暑假期间，我们将在开学后7个工作日内将“中央非税收入统一票据（电子）”发送至电子邮箱，请留意短信提示。重点提示：</p>
                <p>1.本课程资源相关知识产权归北京大学所有；</p>
                <p>2.如以上内容与课程详情页有出入，请以具体课程的课程详情页为准；</p>
                <p>3.购课时请认真阅读此内容，如有疑问请通过客服电话或邮箱咨询沟通，学员付费后将视为学员知情并接受以上规定。</p>
                <p>如有疑问，请通过以下方式详询相关客服人员。 电话：13581690430（工作日9:00-17:30）</p>
                <p>邮箱：ipku@offcn.com（请在邮件中留下电话、帐号、课程名称、详细问题描述、相关截图等信息）</p>
            </div>
            <div class="btn">
                <el-button :type="type" :plain="plain" round :disabled="isDisabled" @click="agreeEvent">我已阅读并同意<span
                        v-if="num > 0">({{ num }})</span></el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'buyGuide',
    data () {
        return {
            dialogVisible: false,
            num: 3,
            type: 'info',
            isDisabled: true,
            plain: true,
            timer: null
        }
    },
    mounted () {

    },
    methods: {
        openInit () {
            this.dialogVisible = true
            this.countdown()
        },
        handleClose () {
            this.dialogVisible = false
            clearInterval(this.timer)
        },
        countdown () {
            this.timer = setInterval(() => {
                this.num--
                if (this.num == 0) {
                    this.type = 'primary'
                    this.isDisabled = false
                    this.plain = false
                    clearInterval(this.timer)
                }
            }, 1000);
        },
        agreeEvent () {
            this.dialogVisible = false
            this.$emit('agree', true)
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .buyStyle {
    border-radius: 16px;

    .el-dialog__header {
        padding: 20px;

        .guideTitle {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
        }
    }

    .el-dialog__body {
        padding: 0;

        .guideContent {
            padding: 0 20px;
            height: 300px;
            overflow-y: auto;

            h3 {
                padding-top: 15px;
                font-size: 14px;
                line-height: 30px;
                color: #333;
            }

            p {
                font-size: 14px;
                line-height: 30px;
                color: #333;
            }
        }

        .btn {
            text-align: center;
            padding: 40px 0;
        }
    }

    /deep/ .el-radio__original {
        display: none !important;
    }

    /deep/.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
        box-shadow: none !important;
    }

}
</style>