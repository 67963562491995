<template>
    <div>
        <el-dialog  :visible.sync="dialogVisible" width="700px" :before-close="handleClose" custom-class="authenStyle"  :close-on-click-modal="false">
            <div class="tagger">
                <span :class="tag==0?'active':''" @click="tagger(0)">认证学习模式</span>
                <span :class="tag==1?'active':''" @click="tagger(1)">免费学习模式</span>
            </div>
            <div class="content" v-if="tag==0">
                <p><span>1</span>16 大课题方向，超 100 门课程，可任选主题学习</p>
                <p><span>2</span>完成学习并通过考核，可获得官网能查询的学习证明，用以完善综合素质档案</p>
                <p><span>3</span>完成学习可生成专业化学习报告</p>
                <span class="buy" @click="goBuy"><i>￥</i>{{price}}</span>
            </div>
            <div class="content" v-else>
                <h6>免费学习模式不提供学习证明</h6>
                <p><span>1</span>全平台 16 个课题方向，超 100 门课程，随心选择，随时学习</p>
                <p><span>2</span>获取专业化项目制学习(PBL)表格，助力学员梳理思路</p>
                <p><span>3</span>学员登录即可查看全程学习日志</p>
                <span class="buy study" @click="golearn">立即参与</span>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <span @click="handleClose">我知道了</span>
            </span> -->

        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dialogVisible:false,
            tag:0,
            price:0
        }
    },
    methods:{
        openInit(price){
            this.dialogVisible = true
            this.price = price
        },
        handleClose(){
            this.dialogVisible = false
        },
        tagger(index){
            this.tag = index
        },
        golearn(){
            this.$emit('superiorLearn')
        },
        goBuy(){
            this.$emit('authenticationMode')
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .authenStyle{
    border-radius: 8px;
    .el-dialog__header{
        border:none !important;
        .el-dialog__headerbtn{
            top:10px;
            right:10px;
        }
    }
    .el-dialog__body{
        padding:10px 40px 45px 40px;
        .tagger{
            display: flex;
            justify-content: space-between;
            span{
                display: block;
                width:300px;
                height:60px;
                background:#F2F2F2;
                border-radius: 6px;
                text-align: center;
                line-height: 60px;
                font-size:20px;
                font-weight: bold;
                color:#999;
                cursor: pointer;
            }
            .active{
                color:var(--change-color);
                background: var(--change-plain-color);
            }
        }
        .content{
            padding-top:20px;
            font-size:16px;
            line-height: 40px;
            color:#333;
            h6{
                font-size: 16px;
                color:#333;
            }
            p{
                span{
                    color:var(--change-color);
                    display: inline-block;
                    width:22px;
                    height:22px;
                    border:1px solid var(--change-color);
                    border-radius: 50%;
                    text-align: center;
                    line-height:22px;
                    margin-right:12px;
                }
            }
            .buy{
                display: block;
                width:200px;
                height:50px;
                background:#DDAA4E;
                border-radius: 25px;
                text-align: center;
                line-height: 50px;
                font-size: 24px;
                color:#fff;
                margin:40px auto 0 auto;
                cursor: pointer;
                i{
                    font-size:18px ;
                }
            }
            .study{
                background:var(--change-color);
                color:#fff;
            }
        }
    }
    .el-dialog__footer{
        padding:0;
        text-align: center;
        border-top:1px solid #EEEEEE;
        font-size:16px;
        color:#507FFF;
        line-height:60px;
        span{
            cursor: pointer;
        }
    }
}
</style>