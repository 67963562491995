<template>
    <div class="studyWrap">
        <div class="topWrap">
            <div class="topInfoWrap main">
                <div class="userInfoWrap">
                    <div class="imgWrap">
                        <el-avatar :src="info.head_img
                            ? info.head_img
                            : require('@/assets/images/head_default.png')
                            " icon="el-icon-user-solid"></el-avatar>
                    </div>
                    <div class="infoWrap">
                        <h1>{{ info.name }}</h1>
                        <p>
                            {{ info.faculty_name }}
                            <span v-if="info.enter_year">· {{ info.enter_year }}级</span><span v-if="info.major_name">
                                · {{ info.major_name }}</span>
                        </p>
                    </div>
                </div>
                <ul class="studyInfoWrap">
                    <li>
                        <p>
                            <span>{{ userInfo.study_second }}</span>
                            小时
                        </p>
                        <p>累计观看时长</p>
                    </li>
                    <li>
                        <p>
                            <span>{{ userInfo.study_day }}</span>
                            天
                        </p>
                        <p>累计学习</p>
                    </li>
                    <li>
                        <p>
                            <span>{{ userInfo.study_credit }}</span>
                            分
                        </p>
                        <p>获得学分</p>
                    </li>
                    <!-- <li>
            <p>
              <span>{{}}</span>
              个
            </p>
            <p>获得证书</p>
          </li> -->
                </ul>
            </div>
        </div>
        <div class="bottomWrap main">
            <div class="bottomLeftWrap">
                <ul>
                    <li v-for="(item, index) in leftMenuList" :key="index" :class="nowActive == item.name ? 'on' : ''"
                        @click="changeLeftMenu(item)">
                        <div>
                            <i class="iconfont" :class="item.icon"></i>
                            {{ item.name }}
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </li>
                </ul>
            </div>
            <div class="bottomRightWrap">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import { checklogin } from '@/api/api';
import { setCookie, getCookie } from '@/assets/js/cookie';
import { mycourseUser } from './assets/api';
import { getstudentinfos } from '../../../api/api';
export default {
    watch: {
        $route: {
            handler: function (val) {
                this.nowActive = val.meta.name;
                this.getInit()
            },
            deep: true,
        },
    },
    data () {
        return {
            leftMenuList: [],
            nowActive: '',
            info: {
                name: '',
                faculty_name: '',
                enter_year: '',
                major_name: '',
                head_img: '',
            },
            userInfo: {
                study_second: '',
                study_day: '',
                study_credit: '',
            },
        };
    },
    mounted () {
        setTimeout(() => {
            this.getInit()
        }, 1000);
    },
    methods: {
        getInit () {
            window.addEventListener('setItem', (e) => {
                if (e.key == 'infos') {
                    let info = JSON.parse(localStorage.getItem('infos'));
                    this.info = {
                        name: info.name,
                        faculty_name: info.faculty_name,
                        enter_year: info.enter_year,
                        major_name: info.major_name,
                        head_img: info.head_img,
                    };

                    this.nowActive = this.$route.meta.name;
                    this.getUser();
                }
            });
            if (JSON.parse(localStorage.getItem('infos'))) {
                let info = JSON.parse(localStorage.getItem('infos'));
                this.info = {
                    name: info.name,
                    faculty_name: info.faculty_name,
                    enter_year: info.enter_year,
                    major_name: info.major_name,
                    head_img: info.head_img,
                };

                this.nowActive = this.$route.meta.name;
                this.getUser();
            }
            if (getCookie('siteid') == '52' || getCookie('siteid') == '56') {
                this.leftMenuList = [
                    {
                        id: 6,
                        name: "我的考试",
                        icon: "icon-icon_a",
                        path: "/MyExam",
                    }
                ]
            } else {
                this.leftMenuList = [
                    {
                        id: 1,
                        name: '我的课程',
                        icon: 'icon-study_wdkc',
                        path: '/mycourse_group',
                    },
                    {
                        id: 2,
                        name: '学习记录',
                        icon: 'icon-study_xxbj',
                        path: '/learn_record',
                    },
                    // {
                    //   id: 3,
                    //   name: "直播日历",
                    //   icon: "icon-study_zbrl",
                    //   path: "/live_calendar",
                    // },
                    {
                        id: 4,
                        name: '学习任务',
                        icon: 'icon-study_xxrw',
                        path: '/learn_task',
                    },
                    {
                        id: 5,
                        name: '读书笔记',
                        icon: 'icon-study_dsbj',
                        path: '/note',
                    },

                    // {
                    //   id: 6,
                    //   name: "教学计划",
                    //   icon: "icon-study_jxjh",
                    //   path: "/plan",
                    // },
                    // {
                    //     id: 7,
                    //     name: '我的证书',
                    //     icon: 'icon-renzhengzhengshu',
                    //     path: '/my_certificate',
                    // },
                ]
            }
        },
        changeLeftMenu (item) {
            this.nowActive = item.name;
            this.$router.push({
                path: item.path,
                meta: {
                    name: item.name,
                },
            });
        },
        getUser () {
            mycourseUser().then((res) => {
                if (res.code == 0) {
                    this.userInfo.study_second = (
                        res.data.info.study_second / 3600
                    ).toFixed(2);
                    this.userInfo.study_day = res.data.info.study_day;
                    this.userInfo.study_credit = res.data.info.study_credit;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.studyWrap {
    .topWrap {
        width: 100%;
        height: 280px;
        background: url(./assets/images/topBg.png) top center no-repeat;
        background-size: 100% 100%;

        .topInfoWrap {
            padding-top: 50px;
            box-sizing: border-box;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .userInfoWrap {
                display: flex;
                align-items: center;

                .imgWrap {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background: #fff;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .el-avatar {
                        width: 94px;
                        height: 94px;
                        line-height: 94px;
                        border-radius: 50%;
                        font-size: 47px;
                    }
                }

                .infoWrap {
                    h1 {
                        font-size: 30px;
                        margin-bottom: 8px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }

            .studyInfoWrap {
                display: flex;
                align-items: center;

                li {
                    margin-left: 80px;
                    text-align: center;
                    font-size: 16px;

                    span {
                        font-size: 30px;
                        margin-bottom: 8px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .bottomWrap {
        margin-top: -80px;
        border-radius: 8px;
        background: #fff;
        display: flex;

        .bottomLeftWrap {
            width: 220px;
            padding: 15px 0;
            box-sizing: border-box;
            border-right: 1px solid #e9eaeb;

            li {
                height: 48px;
                line-height: 48px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                padding: 0 24px;

                &:hover {
                    background: #f5f5f5;
                }

                div {
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 14px;
                        font-size: 24px;
                    }
                }

                &.on {
                    color: var(--change-color);
                }
            }
        }

        .bottomRightWrap {
            width: calc(100% - 221px);
        }
    }
}
</style>
